import { Component, OnInit, ViewEncapsulation, } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators, FormArray } from '@angular/forms';
import { EmailValidators } from 'ngx-validators';

import { CookieService } from 'ngx-cookie-service';
import { MessageService } from 'primeng/api';

import { GeneralService } from '../Services/generalservice.service';
declare var $

import Swal from 'sweetalert2';
import { HttpClient } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { single } from 'rxjs/operators';
@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss'],
    providers: [MessageService, DatePipe]
})
export class profileComponent implements OnInit {
    FStatus: any;
    HomeUrl: any;
    Form: FormGroup;
    W2Form: string | ArrayBuffer | null = null;
    spousecity: any;
    spousestate: any;
    spousecountry: any;
    depllist5: any;
    deptList3: any;
    
    Country: any;
    FiillingStatus: any[];
    deptList6: any;
    SpouseDetails: any;
    selectedFillingStatus: any;

    insertingData: boolean = false;
    image: any;
    lat: any;
    lng: any;
    map: any;
    options: any;
    imagefile: any;
    overlays: any[];

    dialogVisible: boolean;

    markerTitle: string;

    selectedPosition: any;

    infoWindow: any;

    draggable: boolean;

    HomeURL: any;
    logindata: boolean;
    LoginDetails: any;
    ProfileDetails: any;
    Profiledata: any;
    CustomerID: any;
    ADDRESS_Parts1: {};
    ADDRESS1: any;
    Longitude: any;
    Latitude: any;
    CustomerName: any;
    MobileNumber: any;
    DrivingLicense: any;
    DateOfBirth: any;
    SSN: any;
    HNo: any;
    EmailID: any;
    Area: any;
    Landmark: any;
    City: any;
    Pincode: any;
    State: any;
    CountryName: any;
    Section: any;
    deptList: any[];
    arr: any[];
    deptList1: any[];
    deptList2: any[];
    FilledData: any;
    DOB: any;
    DLIMG: any;
    Documents: any;
    Form1099: any;
    AddMoreDoc: any;
    CityID: any[];
    FillingStatusID: any
    StateID: any[];
    CountryID: any[];
    cityName: string;
    City1: any;
    SpouseName: any[];
    S_MobileNumber: any[];
    SpouseDateOfBirth: any[];
    S_SSN: any[];
    S_EmailID: any[];
    S_DOB: any;
    S_DrivingLicense: any[];
    S_CityID: any[];
    S_StateID: any[];
    S_CountryID: any[];
    image3: any;
    image2: any;
    image1: any;
    W2Form1: any;
    DLIMG1: any;
    Form10991: any;
    AddMoreDoc1: any;
    profilevalues: any;
    cont: any;
    StateName: any;
   // Spousevalues: any;
    PDFPathFile: any;
    imagefile3: any;
    selectedFileName: any;
    SpouseID: any;
    pdfDocument: string | ArrayBuffer | null = null;

        constructor(public router: Router, public http: HttpClient, public fb: FormBuilder,
            public generalService: GeneralService, private toast: MessageService, public datePipe: DatePipe
        ) {
            this.LoginDetails = JSON.parse(localStorage.getItem("LoginDetails"));
            debugger
            this.http.get('../assets/WebService.json').subscribe((data: any) => {
                this.HomeUrl = data.Webservice;
            });
            this.ProfileDetails = JSON.parse(localStorage.getItem("ProfileDetails"));
          

  
        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.HomeURL = data.Webservice;
        });

        this.Form = fb.group({
            CustomerName: ['', Validators.required],
            MobileNumber: ['', Validators.required],
            DrivingLicense: ['', Validators.required],
            DLIMG: ['', Validators.required],
            W2Form: ['', Validators.required],
            Form1099: ['', Validators.required],
            AddMoreDoc: ['', Validators.required],
            DOB: ['', Validators.required],
            SSN: ['', Validators.required],
            EmailID: ['', Validators.required],
            CityName: ['', Validators.required],
            StateName: ['', Validators.required],
            CountryName: ['', Validators.required],
            FiillingStatus: ['', Validators.required],

            SpouseName: ['', Validators.required],
            S_MobileNumber: ['', Validators.required],
            S_SSN: ['', Validators.required],
            S_DOB: ['', Validators.required],
            S_DrivingLicense: ['', Validators.required],
            S_EmailID: ['', Validators.required],
            S_CityName: ['', Validators.required],
            S_StateName: ['', Validators.required],
            S_CountryName: ['', Validators.required],
            Image: ['', Validators.required],



        });

            this.GetProfiledetails();
          
        }

    formatDate(date: Date): string {
        return this.datePipe.transform(date, 'yyyy-MM-dd');
    }

    ngOnInit(): void {
      
        this.Getcountries();
        this.Getcities();
        this.Getstates();
        this.GetFiillingStatus();
        this.GetSpouseDetails();
        this.GetProfiledetails();
     
      
  
    }
    
    GetProfiledetails() {
        debugger
        var Profiledetails1 = [];
        Profiledetails1.push({
            CustomerID: this.LoginDetails.CustomerID,
         
        })
            ;

        let formData: FormData = new FormData();
        formData.append('Param', JSON.stringify(Profiledetails1));
        formData.append('Flag', '4');
        var url = "api/Payertax/ProfileDetailsCrud"       
        this.generalService.PostData(url, formData).then((data: any) => {
          
                debugger
            this.profilevalues = data;
            this.SpouseID = this.profilevalues[0].SpouseID;
            //let S_CityName = this.City.find(a => a.CityID === this.profilevalues[0].S_CityID);
            //if (S_CityName) {
            //    this.Form.get('S_CityName')?.setValue(S_CityName);
            //}
            //let S_stateName = this.State.find(a => a.StateID === this.profilevalues[0].S_StateID);
            //if (S_stateName) {
            //    this.Form.get('S_StateName')?.setValue(S_stateName);
            //}
            //let S_country = this.Country.find(a => a.CountryID === this.profilevalues[0].S_CountryID);
            //if (S_country) {
            //    this.Form.get('S_CountryName')?.setValue(S_country);
            //}
            let S_country = this.Country.filter(a => a.CountryID == this.profilevalues[0].S_CountryID);
            this.Form.controls['S_CountryName'].setValue(S_country[0]);

            let S_stateName = this.State.filter(a => a.StateID == this.profilevalues[0].S_StateID);
            this.Form.controls['S_StateName'].setValue(S_stateName[0]);

            let S_CityName = this.City.filter(a => a.CityID == this.profilevalues[0].S_CityID);
            this.Form.controls['S_CityName'].setValue(S_CityName[0]);

            let Cityname = this.City.filter(a => a.CityID == this.profilevalues[0].CityID);
            this.Form.controls['CityName'].setValue(Cityname[0]);

            let statename = this.State.filter(a => a.StateID == this.profilevalues[0].StateID);
            this.Form.controls['StateName'].setValue(statename[0]);

            let countryname = this.Country.filter(a => a.CountryID == this.profilevalues[0].CountryID);
            this.Form.controls['CountryName'].setValue(countryname[0]);

           
           
       
            this.MobileNumber = this.profilevalues[0].MobileNumber
            this.DrivingLicense = this.profilevalues[0].DrivingLicense

            this.DLIMG = "https://localhost:44330" + this.profilevalues[0].DLIMG

           
            this.DOB = this.datePipe.transform(this.profilevalues[0].DOB,'yyyy-MM-dd');
            this.Form.controls['DOB'].setValue(this.DOB);
            console.log(this.profilevalues[0].DOB);
            this.W2Form = "https://localhost:44330" + this.profilevalues[0].W2Form
            this.Form1099 = "https://localhost:44330" + this.profilevalues[0].Form1099
            this.AddMoreDoc = "https://localhost:44330" + this.profilevalues[0].AddMoreDoc


            this.DLIMG1 = this.profilevalues[0].DLIMG

            this.W2Form1 = this.profilevalues[0].W2Form
            this.Form10991 = this.profilevalues[0].Form1099
            this.AddMoreDoc1 = this.profilevalues[0].AddMoreDoc
            this.EmailID = this.profilevalues[0].EmailID
            this.SSN = this.profilevalues[0].SSN;
            this.Form.controls['SSN'].setValue(this.SSN);
         


            this.SpouseName = this.profilevalues[0].SpouseName
            this.CustomerName = this.profilevalues[0].CustomerName
            this.S_MobileNumber = this.profilevalues[0].S_MobileNumber
            this.S_DrivingLicense = this.profilevalues[0].S_DrivingLicense
            this.S_SSN = this.profilevalues[0].S_SSN
            this.S_EmailID = this.profilevalues[0].S_EmailID;
           // this.S_DOB = new Date(this.profilevalues[0].S_DOB);

            this.S_DOB = this.datePipe.transform(this.profilevalues[0].S_DOB, 'yyyy-MM-dd');
            this.Form.controls['S_DOB'].setValue(this.S_DOB);
            console.log(this.profilevalues[0].S_DOB);

           
          
            let fillingryname = this.FilledData.filter(a => a.FillingStatusID == this.profilevalues[0].FillingStatusID);
            this.Form.controls['FiillingStatus'].setValue(fillingryname[0]);

            // Call selectFillingStatus method with the appropriate value
            this.selectFillingStatus(fillingryname[0].FillingStatus);
        });
    }
    Submit(val) {
        debugger
        var Profiledetails = [];
        Profiledetails.push({
            CustomerID: this.LoginDetails.CustomerID,
            CityID: val.CityName.CityID,
            CustomerName: val.CustomerName,
            MobileNumber: val.MobileNumber,
            EmailID: val.EmailID,
            SSN: val.SSN,
            StateID: val.StateName.StateID,
            CountryID: val.CountryName.CountryID,  
           
            FillingStatusID: val.FiillingStatus.FillingStatusID,
            DLIMG: this.DLIMG1,
            W2Form: this.W2Form1,
            Form1099: this.Form10991,
            AddMoreDoc: this.AddMoreDoc1,
            DrivingLicense: val.DrivingLicense,
            CurrentTokenID: this.LoginDetails.CurrentTokenID,
            RoleID: 2,
            PaymentID: this.LoginDetails.PaymentID,
            Password: this.LoginDetails.Password,
            SpouseID: this.SpouseID,
            SpouseName: val.SpouseName,
            S_MobileNumber: val.S_MobileNumber,
            S_DOB: val.S_DOB,
            S_SSN: val.S_SSN,
            S_DrivingLicense: val.S_DrivingLicense,
            S_EmailID: val.S_EmailID,
            S_CityID: val.S_CityName.CityID,
            S_StateID: val.S_StateName.StateID,
            S_CountryID: val.S_CountryName.CountryID,
             DOB: val.DOB,
        });

 

        let formData: FormData = new FormData();
        formData.append('Param', JSON.stringify(Profiledetails));
        formData.append('Flag', '2');
        var url = "api/Payertax/ProfileDetailsCrud"
        // this.http.post(url, formData).pipe(map(res => res)).subscribe((data: any) => {
        this.generalService.PostData(url, formData).then((data: any) => {
            if (data == 'SUCCESS') {
                debugger
                //send mail customer details
                var array = [{
                   CityName: val.CityName.CityName,
                    CustomerName: val.CustomerName,
                    MobileNumber: val.MobileNumber,
                    EmailID: val.EmailID,
                    SSN: val.SSN,
                    StateName: val.StateName.StateName,
                    CountryName: val.CountryName.CountryName,
                    FiillingStatus: val.FiillingStatus.FiillingStatus,
                    DLIMG: this.DLIMG1,
                    W2Form: this.W2Form1,
                    Form1099: this.Form10991,
                    AddMoreDoc: this.AddMoreDoc1,
                    DrivingLicense: val.DrivingLicense,
                    SpouseName: val.SpouseName,
                    S_MobileNumber: val.S_MobileNumber,
                    S_DOB: val.S_DOB,
                    S_SSN: val.S_SSN,
                    S_DrivingLicense: val.S_DrivingLicense,
                    S_EmailID: val.S_EmailID,
                    S_CityName: val.S_CityName.CityName,
                    S_StateName: val.S_StateName.StateName,
                    S_CountryName: val.S_CountryName.CountryName,
                    DOB: val.DOB,
                }]
                var UploadFile = new FormData();
                UploadFile.append("Mail", JSON.stringify(array));
                var url = 'api/Payertax/Sendmail';
                this.generalService.PostData(url, UploadFile).then((data: any) => {

                })
                //if (this.LoginDetails.RoleID == 1) {
                    var UploadFile = new FormData();
                    UploadFile.append("Mail", JSON.stringify(array));
                    var url = 'api/Payertax/SendMail1Admin';
                    this.generalService.PostData(url, UploadFile).then((data: any) => {

                    })
               // }
                this.generalService.ShowAlert('Success', 'Your Profile updated successfully.', 'success')
                this.router.navigate(['Cart'])
                // this.navCtrl.pop();
            }
        });

    }

    detectFiles(event: any, fieldName: string) {
        debugger
        const reader = new FileReader();
        if (event.target.files && event.target.files.length) {
            const file = event.target.files[0];
            reader.onload = () => {
                const imageDataUrl = reader.result as string;
                if (fieldName === 'DLIMG') {
                    debugger
                    this.DLIMG = imageDataUrl;
                } else if (fieldName === 'W2Form') {
                    debugger
                    this.W2Form = imageDataUrl;
                } else if (fieldName === 'Form1099') {
                    debugger
                    this.Form1099 = imageDataUrl;
                }

                //else if (fieldName === 'AddMoreDoc') {
                //    debugger
                //    this.AddMoreDoc = imageDataUrl;
                //}

                const selectedFile = file;
                const idxDot = selectedFile.name.lastIndexOf('.') + 1;
                const extFile = selectedFile.name.substr(idxDot, selectedFile.name.length).toLowerCase();

                const UploadFile = new FormData();
                UploadFile.append('PostedFile', file);
                const url = 'api/Payertax/UploadEmergencyTypeImage';

                this.generalService.PostData(url, UploadFile).then((data: any) => {
                    if (data != null) {
                        debugger
                        if (fieldName === 'DLIMG') {
                            this.DLIMG1 = data;
                            this.DLIMG = this.HomeUrl + data;
                          
                            // Store the DLIMG API return value in a specific variable if needed
                        } else if (fieldName === 'W2Form') {
                            debugger
                            this.W2Form1 = data;
                            this.W2Form = this.HomeUrl + data;
                            // Store the W2Form API return value in a specific variable if needed
                        } else if (fieldName === 'Form1099') {
                            debugger
                            this.Form10991 = data;
                            this.Form1099 = this.HomeUrl + data;
                            // Store the Form1099 API return value in a specific variable if needed
                        }
                        //else if (fieldName === 'AddMoreDoc') {
                        //    debugger
                        //    this.AddMoreDoc1 = data;
                        //    this.AddMoreDoc = this.HomeUrl + data;
                        //    // Store the AddMoreDoc API return value in a specific variable if needed
                        //}
                    }
                });
            };
            reader.readAsDataURL(file);
        }
    }


    //**This is for a single image upload.**//
    detectFilesworking(input) {
        debugger
        //let arr = [];
        //arr.push({ UserID: this.loginDet.Id, TokenID: this.loginDet.TokenID })
        const reader = new FileReader();
        if (input.files.length) {
            debugger
            const file = input.files[0];
            reader.onload = () => {
                debugger
                this.imagefile = reader.result;
                this.image = this.imagefile;
            }
            reader.readAsDataURL(file);
            var selectedFile = file;
            var idxDot = selectedFile.name.lastIndexOf(".") + 1;
            var extFile = selectedFile.name.substr(idxDot, selectedFile.name.length).toLowerCase();
            debugger
            var UploadFile = new FormData();
            UploadFile.append("PostedFile", file);
            var url = 'api/Payertax/UploadEmergencyTypeImage';
            this.generalService.PostData(url, UploadFile).then((data: any) => {
                if (data != null) {
                    debugger
                    this.imagefile = this.HomeUrl + data;
                    this.image = data;
                }
            });

        }
    }
    detectFiles3(input3) {
        const reader = new FileReader();
        if (input3.files.length) {
            const file = input3.files[0];
            this.selectedFileName = file.name; // Store the file name
            reader.onload = () => {
                this.imagefile3 = reader.result;
            }
            reader.readAsDataURL(file);
            var selectedFile = file;
            var idxDot = selectedFile.name.lastIndexOf(".") + 1;
            var extFile = selectedFile.name.substr(idxDot, selectedFile.name.length).toLowerCase();
            if (extFile == "pdf") {
                debugger
                var UploadFile = new FormData();
                UploadFile.append("PostedFile", file);
                var url = 'api/Payertax/UploadEmergencyTypeImage';
                this.generalService.PostData(url, UploadFile).then((data: any) => {
                    if (data != null) {
                        debugger
                        this.imagefile3 = this.HomeUrl + data;
                        this.PDFPathFile = data;

                        this.PDFPathFile = this.PDFPathFile;

                    }
                });
            }
            else {
                alert("Only jpg/jpeg, png files are allowed!");
            }

        }
    }
    get documentForms() {

        return this.Form.get('documents') as FormArray;
    }
    GetDocument(TempId) {
        debugger
        var url = "api/Payertax/get_Documents";
        this.generalService.GetData(url).then(data => {
            debugger

            this.Documents = data;
            this.Documents = this.Documents.filter(a => a.TemporaryId == TempId);

            // Populate Documents section values
            const documents = this.Documents.map(document => {
                return this.fb.group({
                    DocumentName: document.DocumentName,
                    PDFPath: '', // assuming you don't have the PDFPath value at this point
                    imagefile: this.HomeUrl + document.DocPath // assuming you have the imagefile value
                });
            });
            this.Form.setControl('documents', this.fb.array(documents));


        }, err => {
            this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later', 'error');
        })
    }

    Getcountries() {
        debugger
        this.arr = []
        this.arr.push({
            //UserID: this.loginDet.UserID,
            //TokenID: this.loginDet.TokenID,
           

        })
        var UploadFile = new FormData();
        UploadFile.append("Param", JSON.stringify(this.arr));
          UploadFile.append("Flag", '4');

        var url = "api/Payertax/CountryMaster_Crud";
        this.generalService.PostData(url, UploadFile).then(data => {
            debugger
            this.Country = data

         
           



        }, err => {
            this.generalService.ShowAlert('Error', 'Some thing went Wrong', 'error')
        })

    }

    searchcount3(event) {
        debugger
        let filtered: any = [];
        let query = event.query;
        {
            this.deptList = [];
            for (var i = 0; i < this.Country.length; i++) {
                let RollMaster = this.Country[i];
                if (RollMaster.CountryName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(RollMaster);
                }

            }
            debugger
            this.deptList = filtered;
            // this.getemployees()
        }
    }
    GetSpouseDetails() {
        debugger
     
        var UploadFile = new FormData();
     
        UploadFile.append("Flag", this.LoginDetails.CustomerID);

        var url = "api/Payertax/Spouse_Crud";
        this.generalService.PostData(url, UploadFile).then(data => {
            debugger
            this.SpouseDetails = data;

           

        }, err => {
            this.generalService.ShowAlert('Error', 'Some thing went Wrong', 'error')
        })

    }


    Getcities() {
        debugger
        this.arr = []
        this.arr.push({
            //UserID: this.loginDet.UserID,
            //TokenID: this.loginDet.TokenID,


        })
        var UploadFile = new FormData();
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '4');

        var url = "api/Payertax/CityMaster_Crud";
        this.generalService.PostData(url, UploadFile).then(data => {
            debugger
            this.City = data;       


        }, err => {
            this.generalService.ShowAlert('Error', 'Some thing went Wrong', 'error')
        })

    }

    searchcount1(event) {
        debugger
        let filtered: any = [];
        let query = event.query;
        {
            this.deptList1 = [];
            for (var i = 0; i < this.City.length; i++) {
                let RollMaster = this.City[i];
                if (RollMaster.CityName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(RollMaster);
                }

            }
            debugger
            this.deptList1 = filtered;
            // this.getemployees()
        }
    }
    Getstates() {
        debugger
        this.arr = []
        this.arr.push({
            //UserID: this.loginDet.UserID,
            //TokenID: this.loginDet.TokenID,


        })
        var UploadFile = new FormData();
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '4');

        var url = "api/Payertax/StateMaster_CRUD";
        this.generalService.PostData(url, UploadFile).then(data => {
            debugger
            this.State = data


           

        }, err => {
            this.generalService.ShowAlert('Error', 'Some thing went Wrong', 'error')
        })

    }

    searchcount4(event) {
        debugger
        let filtered: any = [];
        let query = event.query;
        {
            this.deptList2 = [];
            for (var i = 0; i < this.State.length; i++) {
                let RollMaster = this.State[i];
                if (RollMaster.StateName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(RollMaster);
                }

            }
            debugger
            this.deptList2 = filtered;
            // this.getemployees()
        }
    }
    GetFiillingStatus() {
        debugger
        this.arr = []
        this.arr.push({
            //UserID: this.loginDet.UserID,
            //TokenID: this.loginDet.TokenID,


        })
        var UploadFile = new FormData();
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '4');

        var url = "api/Payertax/FiillingStatusMaster_Crud";
        this.generalService.PostData(url, UploadFile).then(data => {
            debugger
            this.FilledData = data;

          


        }, err => {
            this.generalService.ShowAlert('Error', 'Some thing went Wrong', 'error')
        })

    }

    searchcount6(event) {
        debugger
        let filtered: any = [];
        let query = event.query;
        {
            this.deptList6 = [];
            for (var i = 0; i < this.FilledData.length; i++) {
                let RollMaster = this.FilledData[i];
                if (RollMaster.FiillingStatus.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(RollMaster);
                }

            }
            debugger
            this.deptList6 = filtered;
            this.FStatus = this.deptList6.find(a => a.FillingStatusID === 1) !== undefined;

            // this.getemployees()
        }
    }

    selectFillingStatus(status: string) {
        this.selectedFillingStatus = status !== 'Single';
    }



}