<div class="container-fluid banner_image breadcrumb__overlay">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="breadcrumb_heading">
                    <div class="banner_heading">Registration</div>
                    <nav aria-label="breadcrumb crumb">
                        <ol class="breadcrumb">
                            <li class="Home"><a routerLink="/">Home >></a></li>
                            <li style="color:white;"><a>Registration</a></li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container">
    <div class="register">
        <div class="row">
            <div class="col-md-4 register-left">
                <!--<img src="../../../assets/img/payertax.webp" alt="" />-->

                <h3>Welcome To</h3>
                <img src="../../../assets/PayerTax/logo.png" />
                <p class="transition">Already have an account? Sign in!</p>
                <input type="submit" name="" value="Login" routerLink="/Profile" /><br />
            </div>
            <div class="col-md-8 register-right">
                <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                        <h3 class="register-heading">Registration</h3>
                        <div class="row register-form">
                            <form [formGroup]="form" (ngSubmit)="onSubmit(form.value)">
                                <div class="col-md-6">
                                    <div class="form-group">

                                        <input [formControl]="name" class="form-control validation-field"
                                               placeholder="Full Name" type="text">
                                        <small class="text-danger" *ngIf="form.get('name').touched">
                                            <span *ngIf="form.get('name').hasError('required')">Full Name is required</span>
                                            <span *ngIf="form.get('name').hasError('minlength')">Minimum of 3 characters</span>
                                        </small>
                                    </div>

                                    <div class="form-group">
                                        <input [formControl]="mobile" class="form-control validation-field"
                                               placeholder="Mobile number" type="text">
                                        <small class="text-danger" *ngIf="form.get('name').touched">
                                            <span *ngIf="form.get('mobile').hasError('required')">mobile number is required</span>
                                            <span *ngIf="form.get('mobile').hasError('minlength')">please enter 10 digits mobile number</span>
                                        </small>
                                    </div>
                                    <div class="form-group">
                                        <input [formControl]="email" class="form-control validation-field" placeholder="Email" type="text">
                                        <small class="text-danger" *ngIf="form.get('email').touched">
                                            <span *ngIf="form.get('email').hasError('required')">Email is required</span>
                                            <span *ngIf="form.get('email').hasError('invalidEmail')">Invalid email address</span>
                                        </small>
                                    </div>

                                    <div class="form-group">
                                        <input [formControl]="password" class="form-control validation-field"
                                               placeholder="Password" type="password" minlength="6">
                                        <small class="text-danger" *ngIf="form.get('password').touched">
                                            <span *ngIf="form.get('password').hasError('required')">Password is required</span>
                                            <span *ngIf="form.get('password').hasError('minlength')">Password isn't long enough, minimum of 6 characters</span>
                                        </small>
                                    </div>

                                    <div class="form-group">
                                        <input [formControl]="confirmPassword" class="form-control validation-field"
                                               placeholder="Confirm Password" type="password">
                                        <small class="text-danger" *ngIf="form.get('confirmPassword').touched">
                                            <span *ngIf="form.get('confirmPassword').hasError('required')">Confirm Password is required</span>
                                            <span *ngIf="form.get('confirmPassword').hasError('mismatchedPasswords')">Passwords do not match</span>
                                        </small>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <!--<img src="../../../assets/lawyersimages/logo-removebg-preview.png" class="logo" style="margin-left: 380px; margin-top: -380px;" />-->
                                    <button class="button-71" role="button"> Save</button>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>