import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import Swal from 'sweetalert2';
import { Subject, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class CartService {

  public cartItemList: any = []
  public ordeDetails: any = []
  public relatedProductDetails:any=[]
  public prodctList = new BehaviorSubject<any>([]);
  public orderList = new BehaviorSubject<any>([]);
  public relatedProdList = new BehaviorSubject<any>([]);

  constructor() { }

    getProducts() {
       
    return this.prodctList.asObservable();
  }
  setProducts(product: any) {
    this.cartItemList.push(...product);
    this.prodctList.next(product)
  }
  addtoCart(products: any) {
    debugger
    this.cartItemList.push(products)
    this.prodctList.next(this.cartItemList);
    this.getTotalPrice();
  }
  getTotalPrice() {
    let grandTotal = 0;
    this.cartItemList.map((a: any) => {
      grandTotal += a.MRp * 1;
    })
    return grandTotal;
  }

  removeCartItems(index: any) { 
    this.cartItemList.splice(index, 1)
    this.prodctList.next(this.cartItemList);
  }

  removeAllcart() {
    this.cartItemList = [];
    this.prodctList.next(this.cartItemList);
  }

  showOrderdetails(orders: any) {    
    this.ordeDetails = []
    this.ordeDetails.push(orders)
    this.orderList.next(this.ordeDetails)
  }
  getOrderNumber() {
    return this.orderList.asObservable();
  }

  setRelatedproductId(productId: any) {
    this.relatedProductDetails = []
    this.relatedProductDetails.push(productId)
    this.relatedProdList.next(this.relatedProductDetails)
  }
  getrelatedProdNumber() {
    return this.relatedProdList.asObservable();
  }
}
