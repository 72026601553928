<!--<div id="welcome" class="welcome-area pt-100 pb-70"></div>-->




<div class="container-fluid mobile_servies_1" style="background-image: url('../../../../assets/img/new imgs/banners/bg_123.jpg');">
  <div class="container">
    <h1 class="service_h">
      Our Services
    </h1><br /><br />
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="row os-animation animated fadeInUp" data-os-animation="fadeInUp" data-os-animation-delay="0.3s" style="animation-delay: 0.3s;">
          <div class="col-lg-10 offset-lg-1">
            <div class="service-img-box">
              <div class="service-img">
                <img src="../../../../assets/img/new imgs/ourservicesimg.jpg" style="border-radius:15px;" />

                <div class="service-link-box service-link-1">
                  <a href="#/APIIntermediates">
                    <p style="color:white" class="service_text">
                      API & INTERMEDIATES
                    </p>
                    <span class="service-link card view_b display_n">
                      View details
                    </span>
                  </a>
                </div>
                <a href="#/APIIntermediates" class="service-link-icon service-link-icon-1 a">
                  <img width="300" height="300" src="../../../../assets/img/new imgs/icons8-capsules-64.png" class="attachment-medium size-medium" alt="api-icon" decoding="async" loading="lazy">
                </a>
                <div class="service-link-box service-link-2">
                  <a href="#/ProcessR&D">
                    <p style="color:white" class="service_text">PROCESS DEVELOPMENT</p>
                    <span class="service-link card view_b display_n">
                      View details
                    </span>
                  </a>
                </div>
                <a href="#/ProcessR&D" class="service-link-icon service-link-icon-2 a">
                  <img width="300" height="300" src="../../../../assets/img/new imgs/icons8-chemistry-53.png" class="attachment-medium size-medium" alt="intermediates-icon" decoding="async" loading="lazy">
                </a>
                <div class="service-link-box service-link-3">
                  <a href="#/MedicinalorSyntheticChemistryServices">
                    <p style="color:white" class="service_text">
                      MEDICINAL & SYNTHETIC CHEMISTRY SERVICES
                    </p>
                    <span class="service-link card view_b display_n">
                      View details
                    </span>
                  </a>
                </div>
                <a href="#/MedicinalorSyntheticChemistryServices" class="service-link-icon service-link-icon-3 a">
                  <img width="300" height="300" src="../../../../assets/img/new imgs/icons8-chemistry-64.png" class="attachment-medium size-medium" alt="cro-icon" decoding="async" loading="lazy">
                </a>
                <div class="service-link-box service-link-4">
                  <a href="#/FineChemicals">
                    <p style="color:white" class="service_text">
                      FINE CHEMICALS AND PYRIDINE <br />DERIVATIVES SYNTHESIS
                    </p>
                    <span class="service-link card view_b display_n">
                      View details
                    </span>
                  </a>
                </div>
                <a href="#/FineChemicals" class="service-link-icon service-link-icon-4 a">
                  <img width="300" height="300" src="../../../../assets/img/new imgs/icons8-test-tube-50 (1).png" class="attachment-medium size-medium" alt="cdmo-icon" decoding="async" loading="lazy">
                </a>
              </div>

            </div>
            <br /> <br />
            <br /><br /><br /> <br /> <br />
            
          </div>
      </div>
    </div>
  </div>
    </div>
</div>

<div class="container mobile_servies">
  <h1 class="service_h"style="font-size:35px;font-weight:bold;">
    Our Services
  </h1><br /><br />
  <div class="col-lg-12 col-xs-6">
    <div class="row">
      <div class="col-lg-4 col-xs-6">
        <div class="card_1" routerLink="/APIIntermediates">
          <h1>  API & INTERMEDIATES </h1><br />
          <img src="../../../../assets/img/new imgs/download2.png" />
          <p>Altrakem is a key API Intermediates manufacturer & supplier in the global marketplace. We work closely with API manufacturers and custom formulators to ensure that the active pharmaceutical ingredients.</p>
          <!--<button type="button" class="btn btn-primary" routerLink="/APIIntermediates">Learn More</button>-->
          <a routerLink="/APIIntermediates" class="learn-more-btn undercolor">
            Learn More  <img src="../../../../assets/img/new imgs/rightarrow.png" style="width:20px;margin-top: -44px;margin-left: 84px;" />
          </a>
        </div>
      </div>
      <div class="col-lg-4 col-xs-6">
        <div class="card_1" routerLink="/ProcessR&D">
          <h1>
            PROCESS DEVELOPMENT
          </h1><br />
          <img src="../../../../assets/img/new imgs/download4.png" />
          <p>Our process development services are focused on the need to develop scalable and robust synthetic routes under a tight timeline .We scale up our clients' chemistry and/or suggest, develop and optimize alternate synthetic routes. </p>
          <!--<button type="button" class="btn btn-primary" routerLink="/ProcessR&D">Learn More</button>-->
          <a routerLink="/ProcessR&D" class="learn-more-btn undercolor">
            Learn More  <img src="../../../../assets/img/new imgs/rightarrow.png" style="width:20px;margin-top: -44px;margin-left: 84px;" />
          </a>
        </div>
      </div>
      <div class="col-lg-4 col-xs-6">
        <div class="card_1" routerLink="/MedicinalorSyntheticChemistryServices">
          <h1>   MEDICINAL & SYNTHETIC CHEMISTRY SERVICES</h1><br />
          <img src="../../../../assets/img/new imgs/download1.png" />
          <p>With expertise in synthetic organic chemistry, we offer custom synthesis service in scales ranging from milligram to kilogram,as well as FTE service.We have successful track records of providing synthetic chemistry service to lead pharmaceutical and biotech companies . </p>
          <!--<button type="button" class="btn btn-primary" routerLink="/MedicinalorSyntheticChemistryServices">Learn More</button>-->
          <a routerLink="/MedicinalorSyntheticChemistryServices" class="learn-more-btn undercolor">
            Learn More  <img src="../../../../assets/img/new imgs/rightarrow.png" style="width:20px;margin-top: -44px;margin-left: 84px;" />
          </a>
        </div>
      </div>
      <div class="col-lg-4 col-xs-6">
        <div class="card_1" routerLink="/FineChemicals">
          <h1>  FINE CHEMICALS AND PYRIDINE DERIVATIVES SYNTHESIS</h1><br />
          <img src="../../../../assets/img/new imgs/download.png" />
          <p>Altrakem’s fine chemical services include a wide range of experience in the synthesis of molecules for agrochemical and pharmaceutical applications. Altrakem’s fine chemical services include a wide range of experience in the synthesis of molecules  </p>
          <!--<button type="button" class="btn btn-primary" routerLink="/FineChemicals">Learn More</button>-->
          <a routerLink="/FineChemicals" class="learn-more-btn undercolor">
            Learn More  <img src="../../../../assets/img/new imgs/rightarrow.png" style="width:20px;margin-top: -44px;margin-left: 84px;" />
          </a>
        </div>
      </div>
    </div>
  </div>
</div>



























<!--<div class="container">
    <h1>
      Our Services
    </h1>
    <div class="row">
      <div class="col-lg-6">
        <div class="col6_p">

          <div class="line_2 ">
            <div class=" line_1 ">
              <h3 class="h3 ">
                &nbsp; API & INTERMEDIATES
              </h3>
            </div>
          </div>

          <p class="p">Altrakem Pharma lifesciences pvt.ltd is a key API Intermediates manufacturer & supplier in the global marketplace. We work closely with API manufacturers and custom formulators to ensure that the active pharmaceutical ingredients.We are providing a broad range of quality pharmaceutical materials at very competitive costs. </p>
          <a routerLink="/APIIntermediates" class="learn-more-btn">
            Learn More

            <img src="../../../../assets/img/new imgs/rightarrow.png" style="width:20px;" />
          </a>
        </div>
      </div>
      <div class="col-lg-6">
        <img src="../../../../assets/img/new imgs/img1.jpg" class="imaag2" />


      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-lg-6">
        <img src="../../../../assets/img/new imgs/altra4.jpg" class="imaag1 line_text" />


      </div>
      <div class="col-lg-6">
        <div class="col6_p line_mobile">
          <div class="line_2 ">
            <div class=" line_1 ">
              <h3 class="h3 ">  &nbsp; PROCESS DEVELOPMENT</h3>
            </div>
          </div>
          <p class="p ">
            Altrakem Pharma lifesciences pvt.ltd Our process development services are focused on the need to develop scalable and robust synthetic routes under a tight timeline. We scale up our clients' chemistry and/or suggest, develop and optimize alternate synthetic routes. Altrakem will provide you with the appropriate solution to meet your expectations on quality, quantity and regulatory needs within the relevant timeline requirements.
          </p>
          <a routerLink="/ProcessR&D" class="learn-more-btn">
            Learn More  <img src="../../../../assets/img/new imgs/rightarrow.png" style="width:20px;" />
          </a>
        </div>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-lg-6">
        <div class="col6_p">
          <div class="line_2">
            <div class=" line_1 ">
              <h3 class="h3 ">
                &nbsp;        MEDICINAL & SYNTHETIC CHEMISTRY SERVICES
              </h3>
            </div>
          </div>

          <p class="p p_content">Altrakem has a dedicated team focused on medicinal chemistry services and have consistently created value for our customers through the supply of novel intermediates and fragments plus supporting in house projects through a flexible range of collaborative R&D Services.</p>
          <a routerLink="/MedicinalorSyntheticChemistryServices" class="learn-more-btn">
            Learn More  <img src="../../../../assets/img/new imgs/rightarrow.png" style="width:20px;" />
          </a>
        </div>
      </div>
      <div class="col-lg-6">
        <img src="../../../../assets/img/new imgs/glass-beakers-filled-with-colorful-liquid.jpg" class="imaag2" />

      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-lg-6">
        <img src="../../../../assets/img/new imgs/1444777_1462997103944.jpg" class="imaag1 line_text" />

      </div>
      <div class="col-lg-6">
        <div class="col6_p line_mobile">
          <div class="line_2 ">
            <div class=" line_1 ">
              <h3 class="h3 ">
                &nbsp;         FINE CHEMICALS AND PYRIDINE DERIVATIVES SYNTHESIS

              </h3>
            </div>
          </div>

          <p class="p p_content">Altrakem’s fine chemical services include a wide range of experience in the synthesis of molecules for agrochemical and pharmaceutical applications. Manufacturer of Pyridine Compounds is specialized in multi-step synthesis of a wide variety of substituted pyridine compounds.</p>
          <a routerLink="/FineChemicals" class="learn-more-btn">
            Learn More  <img src="../../../../assets/img/new imgs/rightarrow.png" style="width:20px;" />
          </a>
        </div>
      </div>
    </div>

  </div>-->

<div class="shape2"><img src="assets/img/shape1.png" alt="image"></div>


