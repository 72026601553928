import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { GeneralService } from '../../Services/generalservice.service';
import { FormGroup, FormBuilder, FormsModule, Validators } from '@angular/forms'
import { MessageService } from 'primeng/api';
import { HttpClient, HttpHandler, HttpResponse, HttpRequest } from '@angular/common/http';
import Swal from 'sweetalert2';

import { Directive, HostListener } from '@angular/core'

import { CartService } from '../../Services/cart.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-product-info',
  templateUrl: './product-info.component.html',
  styleUrls: ['./product-info.component.scss']
})
export class ProductInfoComponent implements OnInit {

  cartData = [];
  ProductData = [];
  CatlogID: any;
  Specdata = []
  CatID: any;
  EnquiryAry: any = [];
  formGroup: any;
  admin: any;
  TemplateId: any;
  MessageText: any;
  //ApiURL: any;
  contactForm: FormsModule;
  spinner: boolean = false;
  imgBase64: any = '';

  HomeUrl: any;
  Catalog_No: any;
  Cas: any;
  ChemicalName: any;
  EnquiryAry1: any;
  Formula: any;
  BoilingPoint: any;
  MolecularWeight: any;
  Moisture: any;
  MeltingPoint: any;
  whatsAppProductURL: string;
  PhysicalState: any;
  Storage: any;
  Apiname: any;
  Density: any;
  Synonym: any;
  MoistureContent: any;
  ProductName: any;
  Purity: any;
  Spectra: any;
  SpecificGravity: any;
  FlashPoint: any;
  RefractiveIndex: any;
  HSCode: any;
  ModelNumber: any;
  EINECS: any;
  MolecularFormula: any;
  PharmaApplications: any;
  OtherApplications: any;
  AgroApplications: any;
  OurStatus: any;
    Structure: any;
 
  constructor(public generalService: GeneralService, public route: ActivatedRoute, public router: Router, public http: HttpClient,) {
    this.http.get('../assets/WebService.json').subscribe((data: any) => {
      this.HomeUrl = data.Webservice;
      this.Catalog_No = this.route.snapshot.paramMap.get('ProductDeatails').replace(/\_/g, " ").trim();
      this.generalService.GetProducts().subscribe(res => {
        if (res.length == 0) {//if category id is null
          this.GetSpecificationdata()
        }
        else {
          this.Catalog_No = res[0].Catalog_No;
          this.GetSpecificationdata()

        }
      })
    });
    debugger
    this.CatID = localStorage.getItem('Cattegoryid')
    this.CatlogID = localStorage.getItem('CatlogID')
    this.cartData = JSON.parse(localStorage.getItem('cartData'))
    console.log(this.cartData)
  }

  ngOnInit(): void {
    debugger
   // this.GetSpecificationdata(this.CatlogID)
    this.ProductData = [];
  }
  GetSpecificationdata() {
    var url = "api/Product/GetProductSpecsUsing_Catlog";
    debugger
    // this.spinner.show();
    var UploadFile = new FormData();
    UploadFile.append("Catlog_No", this.CatlogID);
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      debugger
      if (data != null) {
        this.Specdata = data.Specification;
        this.Catalog_No = (this.Specdata[0].Catalog_No);
        this.ChemicalName = (this.Specdata[0].ChemicalName);
        this.PhysicalState = (this.Specdata[0].PhysicalState);
        this.Cas = (this.Specdata[0].Cas);
        this.Formula = (this.Specdata[0].Formula);
        this.BoilingPoint = (this.Specdata[0].BoilingPoint);
        this.MolecularWeight = (this.Specdata[0].MolecularWeight);
        this.MoistureContent = (this.Specdata[0].MoistureContent);
        this.MeltingPoint = (this.Specdata[0].MeltingPoint);

        this.Storage = (this.Specdata[0].Storage);
        this.Apiname = (this.Specdata[0].Apiname);
        this.Synonym = (this.Specdata[0].Synonym);
        this.Density = (this.Specdata[0].Density);
        this.Structure = (this.Specdata[0].Structure);



        this.Purity = (this.Specdata[0].Purity);
        this.Spectra = (this.Specdata[0].Spectra);
        this.SpecificGravity = (this.Specdata[0].SpecificGravity);
        this.FlashPoint = (this.Specdata[0].FlashPoint);
        this.RefractiveIndex = (this.Specdata[0].RefractiveIndex);
        this.HSCode = (this.Specdata[0].HSCode);


        this.ModelNumber = (this.Specdata[0].ModelNumber);
        this.MolecularFormula = (this.Specdata[0].MolecularFormula);
        this.PharmaApplications = (this.Specdata[0].PharmaApplications);
        this.OtherApplications = (this.Specdata[0].OtherApplications);
        this.AgroApplications = (this.Specdata[0].AgroApplications);
        this.OurStatus = (this.Specdata[0].OurStatus);
           }

    }, err => {
    });
  }
  back() {
    this.router.navigateByUrl('/Products');
  }
  addtoEnquiry(cName, cas) {
    debugger
    var j = 1
    var k = 0
    if (this.cartData.length == 0) {
      this.cartData.push({ P_ID: this.CatlogID, ChemicalName: cName, Cas: cas, Qty: j })
    }
    else {
      for (var i = 0; i < this.cartData.length; i++) {
        if (this.cartData[i].P_ID == this.CatlogID) {
          this.cartData[i].Qty = this.cartData[i].Qty + 1;
          k++;
        }
      }
      if (k == 0) {
        this.cartData.push({ P_ID: this.CatlogID, ChemicalName: cName, Cas: cas, Qty: j })
      }
    }
    localStorage.setItem("cartData", JSON.stringify(this.cartData))
    this.router.navigateByUrl('/Products');
  }

  FilterList(id) {
    debugger
    localStorage.removeItem('catid');
    localStorage.setItem("catid", this.CatID);
    this.router.navigateByUrl('/Products');
  }
  onSubmit1(form: any) {
    debugger;

    this.EnquiryAry = []; this.EnquiryAry.push({ Name: form.name, Companyname: form.Companyname, number: form.number, Email: form.email, Message: form.message })
    var UploadFile = new FormData();
    UploadFile.append("Ticket1", JSON.stringify(this.EnquiryAry));
    var url = "api/Product/SendContatEnquirydetailstoadmin"
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      debugger;

      //this.contactForm.reset();
      Swal.fire(
        'SUCCESS',
        'Your Enquiry has been submitted successfully. Our Team will contact you soon.',
        'success'

      )
      // this.sendsms();
    });

  }




  sendWhatsapp(item) {
    var prodName = ""
    var str = item.ChemicalName.split(" ")//spliting the category name into string array where space occured
    
      prodName = str;
    
    this.whatsAppProductURL = "https://Altrakem.com/%23/Products/" + prodName
    window.open("https://api.whatsapp.com/send?&text=" + this.whatsAppProductURL)
  }



  onSubmit(form: any) {
    debugger;
    this.EnquiryAry1 = [];
    this.EnquiryAry1.push({
      ChemicalName: this.Specdata[0].ChemicalName,
      Catalog_No: this.Specdata[0].Catalog_No,
      Cas: this.Specdata[0].Cas,
      PhysicalState: this.Specdata[0].PhysicalState,
      //MolecularFormula: this.Specdata[0].Formula,
      //BoilingPoint: this.Specdata[0].BoilingPoint,
      MolecularWeight: this.Specdata[0].MolecularWeight,
      Storage: this.Specdata[0].Storage,
      //Apiname: this.Specdata[0].Apiname,
      Synonym: this.Specdata[0].Synonym,
      HSCode: this.Specdata[0].HSCode,
      ModelNumber: this.Specdata[0].ModelNumber,
      MolecularFormula: this.Specdata[0].MolecularFormula,
      Purity: this.Specdata[0].Purity,
      BoilingPoint: this.Specdata[0].BoilingPoint,
      Apiname: this.Specdata[0].Apiname,
      OurStatus: this.Specdata[0].OurStatus,
      Formula: this.Specdata[0].Formula,
      MoistureContent: this.Specdata[0].MoistureContent,
      MeltingPoint: this.Specdata[0].MeltingPoint,
      Density: this.Specdata[0].Density,
      Structure: this.Specdata[0].Structure,
      Spectra: this.Specdata[0].Spectra,
      SpecificGravity: this.Specdata[0].SpecificGravity,
      FlashPoint: this.Specdata[0].FlashPoint,
      RefractiveIndex: this.Specdata[0].RefractiveIndex,
      PharmaApplications: this.Specdata[0].PharmaApplications,
      OtherApplications: this.Specdata[0].OtherApplications,
      AgroApplications: this.Specdata[0].AgroApplications,
      EINECS: this.Specdata[0].EINECS,
      OpticalRotation: this.Specdata[0].OpticalRotation,


    });


    this.EnquiryAry = []; this.EnquiryAry.push({ name: form.name, number: form.number, email: form.email, message: form.message })
    var UploadFile = new FormData();
    UploadFile.append("Ticket1", JSON.stringify(this.EnquiryAry));
    UploadFile.append("Ticket2", JSON.stringify(this.EnquiryAry1));
    var url = "api/Product/EnquiryMailToAdmin1"
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      debugger;


      Swal.fire(
        'SUCCESS',
        'Your Enquiry has been submitted successfully. Our Team will contact you soon.',
        'success'

      )
      window.location.reload();
    });

  }

}
