import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { GeneralService } from '../../Services/generalservice.service';
import { FormGroup, FormBuilder, FormsModule, Validators } from '@angular/forms'
import { MessageService } from 'primeng/api';
import { HttpClient, HttpHandler, HttpResponse, HttpRequest } from '@angular/common/http';
import Swal from 'sweetalert2';

import { Directive, HostListener } from '@angular/core'

import { CartService } from '../../Services/cart.service';
import { Router, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss']
})
export class PdfViewerComponent implements OnInit {
    pdfSrc: string = 'path/to/your/pdf.pdf';
    whatsAppProductURL: string;
    HomeUrl: any;
    Judgeid: string;
    ProductData: any[];
    cartData: any;
    CatID: string;
    docupdf: any;
    PDFdocPath: any;
    PDFPath: any;
    zoomValue: string;
    PDFPath1: any;
    constructor(public generalService: GeneralService, public route: ActivatedRoute, public router: Router, public http: HttpClient,) {
        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            debugger
            this.HomeUrl = data.Webservice;
           this.PDFPath1="http://localhost:54429/Content/PDFS/sample.pdf"
           // this.Judgeid = this.route.snapshot.paramMap.get('ProductDeatails').replace(/\_/g, " ").trim();

            this.Judgeid='3'

            this.generalService.GetProducts().subscribe(res => {
                if (res.length == 0) {//if category id is null
                    this.GetSpecificationdata()
                }
                else {
                    this.Judgeid = res[0].Judgeid;
                    this.GetSpecificationdata()

                }
            })
        });
       
        this.CatID = localStorage.getItem('Judgeid')
      
        this.cartData = JSON.parse(localStorage.getItem('cartData'))
        console.log(this.cartData)
    }

    ngOnInit(): void {
        debugger
        this.GetSpecificationdata();
        //this.ProductData = [];
    }
    GetSpecificationdata() {
        debugger
        var url = "api/LawyerChoices/Get_judgementsBasedOnCourtId";
        var UploadFile = new FormData();
        UploadFile.append("Param1", '3');

        this.generalService.PostData(url, UploadFile).then((data: any) => {
            if (data && data.length > 0 && data[0].PDFPath) {
                debugger
                this.PDFPath = "http://localhost:54429/" + data[0].PDFPath;
            } else {
                console.error('PDFPath is undefined or the data format is incorrect');
            }
        }, err => {
            console.error('Error fetching data from the API');
        });
    }

    back() {
        this.router.navigateByUrl('/Products');
    }






    sendWhatsapp(item) {
        var prodName = ""
        var str = item.ChemicalName.split(" ")//spliting the category name into string array where space occured

        prodName = str;

        this.whatsAppProductURL = "https://Altrakem.com/%23/Products/" + prodName
        window.open("https://api.whatsapp.com/send?&text=" + this.whatsAppProductURL)
    }



 

}
