<div class="container-fluid banner_image breadcrumb__overlay">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="breadcrumb_heading">
                    <div class="banner_heading">Login</div>
                    <nav aria-label="breadcrumb crumb">
                        <ol class="breadcrumb">
                            <li class="Home"><a routerLink="/">Home >></a></li>
                            <li style="color:white;"><a>Login</a></li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</div><br />
      <div class="container">
         <div class="card">
           <div class="main">
                 <section class="sign-in">
                      <div class="container">
                          <div class="signin-content">
                              <div class="row">
                                  <div class="col-lg-1"></div>
                                  <div class="col-lg-5">
                                      <div class="signin-image">
                                          <figure>
                                             
                                              <img src="../../assets/PayerTax/Banners/signup-image.jpg" alt="sing up image">
                                          </figure>
                                      </div>
                                  </div>
                                  <div class="col-lg-5">

                                      <div class="signin-form">
                                          <h2 class="form-title">Login</h2>
                                              <div class="form-group">
                                                  <label for="your_name"><i class="zmdi zmdi-account material-icons-name"></i></label>
                                                  <input type="text" name="mobile"  [(ngModel)]="mobile" id="Mobile" placeholder="Your Mobile Number" />
                                              </div>
                                              <div class="form-group">
                                                  <label for="your_pass"><i class="zmdi zmdi-lock"></i></label>
                                                  <input type="password" name="password" [(ngModel)]="password" id="txtPassword" placeholder="Password" />
                                              </div>
                                              <div class="form-group">

                                                  <input name="__RequestVerificationToken" type="hidden" value="CfDJ8DkUohT2IQZEm0GU8xBRCcGMpvKW6zQD_rjnFZarQvGt40nkPfLoPQ7bHurxyfuNJSss_C3-oFGQfv8ALgF_0eAObNUYhnHbajcptZCKHpv1sustLru_3le-HYxxFHqTLQLP3JS6T8802W3sJgipGLc" />
                                              </div>
                                                 
                                          <button class="button-71" role="button" (click)="Login(mobile,password)"> Login</button>
                                      </div><br />
                                            <p class="signup-image-link">Don't have an account yet?  <a routerLink="/Registration"> Sign Up</a></p>

                                  </div>
                                  <div class="col-lg-1"></div>
                              </div>
                          </div>
                      </div>
                  </section>
              </div>
          </div>
                </div>