  <!-- Carousel Start -->
<div class="container-fluid">
    <div id="carousel" class="carousel slide" data-ride="carousel">
        <ol class="carousel-indicators">
            <li data-target="#carousel" data-slide-to="0" class="active"></li>
            <li data-target="#carousel" data-slide-to="1"></li>
            <li data-target="#carousel" data-slide-to="2"></li>
            <li data-target="#carousel" data-slide-to="3"></li>
            <li data-target="#carousel" data-slide-to="4"></li>
            <li data-target="#carousel" data-slide-to="5"></li>
            <li data-target="#carousel" data-slide-to="6"></li>
        </ol>
        <div class="carousel-inner">
            <div class="carousel-item active">
                <img src="../../../../assets/PayerTax/tax-preparation.jpg" alt="Carousel Image" />
                <div class="carousel-caption">
                    <h1 class="animated fadeInLeft">Income Tax: Levied on earnings, often progressive. </h1>
                    <!--<p class="animated fadeInRight">Lorem ipsum dolor sit amet elit.</p>-->
                </div>
            </div>
            <div class="carousel-item ">
                <img src="../../../../assets/PayerTax/Banners/1613632056582.jpg" alt="Carousel Image" />

                <div class="carousel-caption">
                    <h1 class="animated fadeInLeft">Corporate Tax: On business profits, rates vary.</h1>
                    <!--<p class="animated fadeInRight">Lorem ipsum dolor sit amet elit.</p>-->
                </div>
            </div>

            <div class="carousel-item ">
                <img src="../../../../assets/PayerTax/Banners/Untitled-design-74.jpg" alt="Carousel Image" />

                <div class="carousel-caption">
                    <h1 class="animated fadeInLeft">Property Tax: Based on property value.</h1>
                    <!--<p class="animated fadeInRight">Lorem ipsum dolor sit amet elit.</p>-->
                </div>
            </div>

            <div class="carousel-item">
                <img src="../../../../assets/PayerTax/breadcrumb.jpg" alt="Carousel Image" />
                <div class="carousel-caption">
                    <h1 class="animated fadeInLeft">Sales Tax: Added to goods/services.</h1>
                    <!--<p class="animated fadeInRight">Lorem ipsum dolor sit amet elit.</p>-->
                </div>
            </div>


            <div class="carousel-item">
                <img src="../../../../assets/PayerTax/tax3.webp" alt="Carousel Image" />
                <div class="carousel-caption">
                    <h1 class="animated fadeInLeft">VAT: Consumption tax at production stages.</h1>
                    <!--<p class="animated fadeInRight">Lorem ipsum dolor sit amet elit.</p>-->
                </div>
            </div>
            <div class="carousel-item">
                <img src="../../../../assets/PayerTax/tax-business.webp" alt="Carousel Image" />
                <div class="carousel-caption">
                    <h1 class="animated fadeInLeft">Excise Tax: On specific goods, e.g., tobacco.</h1>
                    <!--<p class="animated fadeInRight">Lorem ipsum dolor sit amet elit.</p>-->
                    <!--<p class="animated fadeInRight">Lorem ipsum dolor sit amet elit. Mauris odio mauris...</p>-->
                    <!--<a class="btn animated fadeInUp" href="#">Get free consultation</a>-->
                </div>
            </div>
            <div class="carousel-item">
                <img src="../../../../assets/PayerTax/y.jpg" alt="Carousel Image" />
                <div class="carousel-caption">
                    <h1 class="animated fadeInLeft">Capital Gains Tax: From asset sales.</h1>
                    <!--<p class="animated fadeInRight">Lorem ipsum dolor sit amet elit.</p>-->
                    <!--<p class="animated fadeInRight">Lorem ipsum dolor sit amet elit. Mauris odio mauris...</p>-->
                    <!--<a class="btn animated fadeInUp" href="#">Get free consultation</a>-->
                </div>
            </div>
        </div>

        <a class="carousel-control-prev" href="#carousel" role="button" data-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" href="#carousel" role="button" data-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
        </a>
    </div>
</div>
<br />
<div class="container">
    <div class="row">
        <h1>What We Do</h1>
        <div class="col-lg-12">
            <div class="shape-container">
                <div class="shape"></div>
            </div>

            <div class="info">
                <div class="shape-container">
                    <div class="shape"></div>
                </div>

                <div class="content">
                    <div class="image-container">
                        <img src="https://cdn.pixelsum.com/file/Illustrations/Smash/Web-design.png" alt="Web Design Image" class="image" style="width:40%;display:contents">
                    </div>
                    <div>
                        <div></div>
                        <p>
                            At Prayer Tax, we offer a range of contribution options to support our mission of nurturing spiritual growth and fostering
                            religious harmony.
                            <br />
                            Our packages are designed to cater to different levels of commitment and involvement.
                            <br />
                            Whether you choose the Silver, Gold, or Platinum package, your contribution makes a significant impact on the
                            programs and services we provide to faith communities.<br />
                            Each package represents a unique opportunity to contribute to
                            the spiritual development of individuals and the overall well-being of our society.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
<div class="container-fluid">
    <div id="parallax-world-of-ugg">
        <section>
            <div class="parallax-three">
                <h2>Our Services</h2>
            </div>
        </section>

        <section>
            <div class="block">
                <div>
                    <b>Tax Return Filing:</b> At Prayer, we offer comprehensive income tax return filing services for U.S. citizens. Our streamlined process ensures that your tax return is prepared accurately and submitted on time. We handle all types of tax returns, including individual, joint, and business returns.
                </div>
                <div class="line-break margin-top-10"></div>
                <div class="margin-top-10">
                    <b>Status Updates:</b> We understand the importance of transparency and keeping you informed throughout the tax filing process. With our user-friendly online portal, you can easily track the progress of your tax return. Our status updates include:
                </div>
                <div style="text-align:end">
                    <a routerLink="/Packages" style="color: #0183ce; font-size: 17px;">
                        Read More ->>
                    </a>
                </div>
            </div>
            
        </section>
    </div>
</div>

