import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeOneComponent } from './components/home-one/home-one.component';
import { HomeTwoComponent } from './components/home-two/home-two.component';
import { HomeThreeComponent } from './components/home-three/home-three.component';
import { HomeFourComponent } from './components/home-four/home-four.component';
import { HomeFiveComponent } from './components/home-five/home-five.component';
import { HomeSixComponent } from './components/home-six/home-six.component';
import { HomeSevenComponent } from './components/home-seven/home-seven.component';
import { HomeEightComponent } from './components/home-eight/home-eight.component';
import { HomeNineComponent } from './components/home-nine/home-nine.component';
import { HomeTenComponent } from './components/home-ten/home-ten.component';
import { HomeElevenComponent } from './components/home-eleven/home-eleven.component';
import { HomeTwelveComponent } from './components/home-twelve/home-twelve.component';
import { HomeThirteenComponent } from './components/home-thirteen/home-thirteen.component';
import { BlogDetailsComponent } from './components/blog-details/blog-details.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './pagelayout/home/home.component';
import { AboutUsComponent } from './pagelayout/about-us/about-us.component';
import { PageHeaderComponent } from './pagelayout/page-header/page-header.component';
import { PageFooterComponent } from './pagelayout/page-footer/page-footer.component';
import { ProductDetailsComponent } from './pagelayout/product-details/product-details.component'
import { ProductInfoComponent } from './pagelayout/product-info/product-info.component';
import { VisionMissionComponent } from './pagelayout/vision-mission/vision-mission.component';
import { LawyersHomeComponent } from './pagelayout/lawyers-home/lawyers-home.component';
import { JudgesComponent } from './pagelayout/judges/judges.component';
import { PdfViewerComponent } from './pagelayout/pdf-viewer/pdf-viewer.component';
import { RegistrationComponent } from './pagelayout/registration/registration.component';
import { profileComponent } from './profile/profile.component';
import { ContactComponent } from './components/common/contact/contact.component';
import { SubscriptionComponent } from './subscription/subscription.component'
const routes: Routes = [


  { path: 'home', component: HomeTwoComponent },
  { path: 'login', component: LoginComponent },
  { path: 'Home', component: HomeComponent },
  { path: 'about-us', component: AboutUsComponent },
  { path: 'PageHeader', component: PageHeaderComponent },
  { path: 'PageFooter', component: PageFooterComponent },
    { path: 'Judges', component: JudgesComponent },
    { path: 'LawerHome', component: LawyersHomeComponent },
    { path: 'contact', component: ContactComponent },
    { path: 'ShowPdf', component: PdfViewerComponent },
  { path: 'Products', component: ProductDetailsComponent },
    { path: 'Registration', component: RegistrationComponent },
    { path: 'home', component: HomeTwoComponent },
    { path: 'Profile', component: profileComponent },
    { path: 'Packages', component: SubscriptionComponent },
    // { path: 'Product/:ProductDeatails', component: ProductInfoComponent },
  { path: 'home-One', component: HomeOneComponent },
  { path: 'home-three', component: HomeThreeComponent },
  { path: 'home-four', component: HomeFourComponent },
  { path: 'home-five', component: HomeFiveComponent },
  { path: 'home-six', component: HomeSixComponent },
  { path: 'home-seven', component: HomeSevenComponent },
  { path: 'home-eight', component: HomeEightComponent },
  { path: 'home-nine', component: HomeNineComponent },
  { path: 'home-ten', component: HomeTenComponent },
  { path: 'home-eleven', component: HomeElevenComponent },
  { path: 'home-twelve', component: HomeTwelveComponent },
  { path: 'home-thirteen', component: HomeThirteenComponent },
  { path: 'blog-details', component: BlogDetailsComponent },
  { path: 'vision-mission', component: VisionMissionComponent },
  

    // Here add new pages component

    { path: '**', component: HomeTwoComponent } // This line will remain down from the whole pages component list
];

//@NgModule({
//    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
//    exports: [RouterModule],
 
//})
//export class AppRoutingModule { }

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            //preloadingStrategy: PreloadAllModules, // <- comment this line for activate lazy load
            //relativeLinkResolution: 'legacy',
            useHash: true
        })
    ],
    exports: [
        RouterModule
    ]
}) 
export class AppRoutingModule { }
