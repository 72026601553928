import { Component, OnInit } from '@angular/core';
import { GeneralService } from '../../Services/generalservice.service';
import { HttpClient, HttpHandler, HttpResponse, HttpRequest } from '@angular/common/http';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

declare var $


@Component({
  selector: 'app-judges',
  templateUrl: './judges.component.html',
  styleUrls: ['./judges.component.scss']
})
export class JudgesComponent implements OnInit {
    loginDet: any;
    arr: any[];
    dataResult: any;
    logindata: string;
    TotalSCJudgements: any;
    TotalAPJudgements: any;
    TodaysTSJudgements: any;
    manohar: boolean = false;
   // PDFPath: string;
    HomeUrl: any;
    PDFPath: SafeResourceUrl;

    constructor(private sanitizer: DomSanitizer,public generalService: GeneralService, public fb: FormBuilder, public http: HttpClient, public router: Router,
        private route: ActivatedRoute) {
        debugger
     
        this.loginDet = JSON.parse(localStorage.getItem("LoginDetails"));
        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            debugger
            this.HomeUrl = data.Webservice;

        });
    }

    ngOnInit(): void {
        this.GetJudgements(1);
        this.getDashboardCount();
    }

    Getpdf() {
        debugger
        this.manohar = true;
       
    }

    getDashboardCount() {
        debugger

        var URL = 'api/LawyerChoices/dashboardcount';
        this.generalService.GetData(URL).then((data: any) => {
            debugger
            if (data != null) {
                debugger
                var Details = data[0]
                // this.spinner.hide();
                this.TotalSCJudgements = Details.SCJudgements
                this.TotalAPJudgements = Details.ApJudgements
                this.TodaysTSJudgements = Details.TSJudgements

            }
            else {

                this.generalService.ShowAlert('ERROR', 'Something Went wrong', 'error');
            }
        }, err => {

            this.generalService.ShowAlert('ERROR', 'Something Went wrong', 'error');
        });
    }

    Getpdf1(val) {
        debugger
        var url = "api/LawyerChoices/Get_judgementsPDF_basedon_judgmentid";
        var UploadFile = new FormData();
        UploadFile.append("Param1", val);

        this.generalService.PostData(url, UploadFile).then((data: any) => {
            if (data && data.length > 0 && data[0].PDFPath) {
                debugger
                this.PDFPath = this.sanitizer.bypassSecurityTrustResourceUrl(this.HomeUrl + data[0].PDFPath);
                       this.manohar = true; // Set the variable to true to make the PDF visible
            } else {
                console.error('PDFPath is undefined or the data format is incorrect');
            }
        }, err => {
            console.error('Error fetching data from the API');
        });
    }


    GetJudgements(value) {
        debugger
        this.arr = []
        this.arr.push({
            //UserID: this.loginDet.UserID,
            //TokenID: this.loginDet.TokenID,

        })

        var UploadFile = new FormData();
        UploadFile.append("Param1",value);
       // UploadFile.append("Flag", '4');
        var url = "api/LawyerChoices/Get_judgementsBasedOnCourtId";
        this.generalService.PostData(url, UploadFile).then(data => {
            debugger
            this.dataResult = data;
            //location.reload();
            this.logindata = JSON.stringify(data[0]);
            localStorage.setItem("LoginDetails", this.logindata);
            console.log('Navigating to Judgement page...');
            this.router.navigate(['/Judges']);
           
        },
            err => {
                this.generalService.ShowAlert('ERROR', 'Something went wrong,please try agian later', 'error');
            })
    }




}
