<div class="container-fluid banner_image breadcrumb__overlay">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="breadcrumb_heading">
          <div class="banner_heading">About Us</div>
          <nav aria-label="breadcrumb crumb">
            <ol class="breadcrumb">
              <li class="Home"><a routerLink="/">Home >></a></li>
              <li style="color:white;"><a>About Us</a></li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
  </div>
</div>


<section class="about-section">
    <div class="container">
        <div class="row">
            <div class="content-column col-lg-6 col-md-12 col-sm-12 order-2">
                <div class="inner-column">
                    <div class="sec-title">
                        <h2>Welcome To...................<br>Payer Tax</h2>
                    </div>
                    <div class="text">
                        Prayer Tax is a trusted provider of professional tax services dedicated to <b>
    helping U.S. citizens navigate the complexities of filing their income tax returns.</b> With our team of experienced 
           tax experts, we strive to ensure a smooth and hassle-free tax filing experience for our clients.
                       
                    </div>
                    <div class="text">
                        Our team understands the importance of staying up-to-date with the latest tax laws and regulations, ensuring that you receive 
                        accurate guidance and maximize your tax benefits. We are committed to providing personalized attention to each client and 
                        addressing their unique tax requirements.
                    </div>
                    <ul class="list-style-one">
                        <li>Siliver</li>
                        <li>Gold</li>
                        <li>Platinum</li>
                    </ul>
                    <div class="btn-box">
                        <a href="#" class="theme-btn btn-style-one">Contact Us</a>
                    </div>
                </div>
            </div>

            <!-- Image Column -->
            <div class="image-column col-lg-6 col-md-12 col-sm-12">
                <div class="inner-column wow fadeInLeft">
                    <figure class="image-1">
                        <a href="#" class="lightbox-image" data-fancybox="images">
                            <img src="../../../assets/PayerTax/about.jpg"  alt="">
                        </a>
                    </figure>
                    <figure class="image-2">
                        <a href="#" class="second" data-fancybox="images">
                            <img src="../../../assets/PayerTax/aboutus.jpg" alt=""/>
                        </a>
                    </figure>
                </div>
            </div>
        </div>
    </div>
</section>