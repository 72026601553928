import { Component, OnInit, ViewEncapsulation,  } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { EmailValidators } from 'ngx-validators';

import { CookieService } from 'ngx-cookie-service';

import { GeneralService } from '../../Services/generalservice.service'
declare var $

import Swal from 'sweetalert2';
@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent  {
    public router: Router;
    public form: FormGroup;
    public name: AbstractControl;
    public mobile: AbstractControl;
    public email: AbstractControl;
    public password: AbstractControl;
    public confirmPassword: AbstractControl;
    arr: any[];

    constructor(router: Router, fb: FormBuilder, public generalService: GeneralService) {


        this.router = router;
        this.form = fb.group({
            name: ['', Validators.compose([Validators.required, Validators.minLength(3)])],
            mobile: ['',],
            email: ['', Validators.compose([Validators.required, emailValidator])],
            password: ['', Validators.required],
            confirmPassword: ['', Validators.required]
        }, { validator: matchingPasswords('password', 'confirmPassword') });

        this.name = this.form.controls['name'];
        //this.name = this.form.controls['Lastname'];
        this.mobile = this.form.controls['mobile'];
        this.email = this.form.controls['email'];
        this.password = this.form.controls['password'];
        this.confirmPassword = this.form.controls['confirmPassword'];
    }

    public onSubmit(value: Object): void {
        debugger
        this.arr = []
        this.arr.push({
            CustomerName: this.name.value,
            MobileNumber: this.mobile.value,
            EmailID: this.email.value,
            Password: this.password.value,
            confirmPassword: this.confirmPassword.value,
            RoleID:2
        })
        var UploadFile = new FormData();
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '1');
        var url = "api/Payertax/ProfileDetailsCrud";
        this.generalService.PostData(url, UploadFile).then(data => {
            debugger         
            if (data != null) {
                this.generalService.ShowAlert("Success", "Registared successfully", "Success");
                this.router.navigate(['/Profile'])
            }
            else {
                this.generalService.ShowAlert("WARNING", "Please Enter Valid Credintials", "warning");
            }
        }, error => ({
            title: 'ERROR',
            text: 'Something went wrong. Please try again later.',
            type: 'error',
            confirmButtonText: 'Ok'

        })
        );

    }

}

export function emailValidator(control: FormControl): { [key: string]: any } {
    var emailRegexp = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/;
    if (control.value && !emailRegexp.test(control.value)) {
        return { invalidEmail: true };
    }
}

export function matchingPasswords(passwordKey: string, passwordConfirmationKey: string) {
    return (group: FormGroup) => {
        let password = group.controls[passwordKey];
        let passwordConfirmation = group.controls[passwordConfirmationKey];
        if (password.value !== passwordConfirmation.value) {
            return passwordConfirmation.setErrors({ mismatchedPasswords: true })
        }
    }
}
