import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-demo-sidebar',
    templateUrl: './demo-sidebar.component.html',
    styleUrls: ['./demo-sidebar.component.scss']
})
export class DemoSidebarComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

    classApplied = false;
    toggleClass() {
        this.classApplied = !this.classApplied;
    }

}