<!--<div id="team" class="team-area ptb-50">
    <div class="container">
        <div class="section-title">
            <h2 style="margin-left:-30px">Our<span>&nbsp; Customers</span></h2>
        </div>
        
            <div class="row mobileMargin mb-9 ">
                <marquee width="10%" direction="left" height="10%" margin-left="65px">
                    <div class="col-lg-2 col-md-2 col-xs-6 col-xs-4">
                        <img src="../../../assets/img/reaxhetro-removebg-preview.png" style="margin-bottom: 36px;" />&nbsp;
                        <img src="../../../../assets/img/reaxmanchester-removebg-preview.png" />&nbsp;
                        </div>
    <div class="col-lg-2 col-md-2 col-xs-6 col-xs-4">
                        <img src="../../../../assets/img/reaxthermo-removebg-preview.png" style="margin-top: -38px;" />&nbsp;
                        </div>

    <div class="col-lg-2 col-md-2 col-xs-6 col-xs-4">
                        <img src="../../../../assets/img/reaxteva-removebg-preview.png" style="margin-bottom: -20px;" />&nbsp;
                        </div>

    <div class="col-lg-2 col-md-2 col-xs-6  col-xs-4">
                        <img src="../../../../assets/img/reaxtci-removebg-preview.png" style="margin-bottom:28px;" />&nbsp;
                        </div>

    <div class="col-lg-2 col-md-2 col-xs-6  col-xs-4">
                        <img src="../../../../assets/img/reaxneuland-removebg-preview.png" style="margin-bottom: 50px;" />&nbsp;
                        </div>

    <div class="col-lg-2 col-md-2 col-xs-6  col-xs-4">
                        <img src="../../../../assets/img/reaxmsn-removebg-preview.png" style="margin-bottom: 26px;" />&nbsp;
                        <img src="../../../assets/img/reaxalembic-removebg-preview.png" style="margin-bottom: -20px;" />&nbsp;
                        <img src="../../../assets/img/reaxenamic-removebg-preview.png" style="margin-bottom: 20px;" />&nbsp;

                        <img src="../../../assets/img/reaxaksci-removebg-preview.png" style="margin-bottom: 20px;" />&nbsp;
                        <img src="../../../assets/img/reaxaaron-removebg-preview.png" style="margin-bottom: 36px;" />&nbsp;
                        <img src="../../../assets/img/reaxbtc-removebg-preview.png" style="margin-bottom: 19px" />&nbsp;
                        <img src="../../../assets/img/reaxtoronto-removebg-preview.png" style="margin-bottom: 1px" />


                    </div>

    </marquee>
            </div>
        </div>


    </div>-->


<div class="container">
    <div class="row">
        <div class="col-lg-12 col-md-12 col-ms-12 col-xs-12 padding_l_0">
            <h2>Our Certificates</h2>
           
        </div>
    </div>
    <div class="row">
      <div class="col-lg-3 col-md-3 col-xs-6 img_brder">
        <img src="../../../../assets/img/new imgs/iso.png" style="margin-left: -18%;"/>
      </div>
      <div class="col-lg-3 col-md-3 col-xs-6 img_brder">
        <img src="../../../../assets/img/new imgs/iso1.png" style="margin-left: -53%;"/>
      </div>
      <div class="col-lg-3 col-md-3 col-xs-6 img_border img">
        <img src="../../../../assets/img/new imgs/iso2.png"style="width: 48%;margin-top: 6%;margin-left:-63%;"/>
      </div>
      </div>
    </div>
