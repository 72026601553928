<div class="container-fluid banner_image breadcrumb__overlay">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="breadcrumb_heading">
                    <div class="banner_heading">Customer Profile Details</div>
                    <nav aria-label="breadcrumb crumb">
                        <ol class="breadcrumb">
                            <li class="Home"><a routerLink="/">Home >></a></li>
                            <li style="color:white;"><a>Customer Profile Details</a></li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</div><br />


      <div class="container bootstrap snippets bootdeys">
          <form>

              <div class="card">
                  <b> Customer Info</b>

                  <div class="row">
                      <div class="col-lg-2">
                          <img src="https://bootdey.com/img/Content/avatar/avatar6.png" class="img-avtar " alt="User avatar">
                      </div>
                      <div class="scheduler-border " [formGroup]="Form">
                          <div class="row">
                              <div class="col-lg-4">
                                  <div class="form-group">
                                      <label><p>Customer Name</p></label>
                                      <input class="form-control" formControlName="CustomerName" [(ngModel)]="CustomerName" type="text" placeholder="CustomerName">
                                      <small style="color:red" class="text-danger" *ngIf="Form.controls.CustomerName.touched && Form.controls.CustomerName.errors?.required">CustomerName is required</small>
                                  </div>
                              </div>

                              <div class="col-lg-4">
                                  <div class="form-group">
                                      <label><p>Mobile Number</p></label>
                                      <input class="form-control" formControlName="MobileNumber" type="text" [(ngModel)]="MobileNumber" placeholder="Mobile Number">
                                      <small style="color:red" class="text-danger" *ngIf="Form.controls.MobileNumber.touched && Form.controls.MobileNumber.errors?.required">Mobile Number is required</small>
                                  </div>
                              </div>

                              <div class="col-lg-4">
                                  <div class="form-group">
                                      <label><p>EmailID</p></label>
                                      <input class="form-control" formControlName="EmailID" type="text" [(ngModel)]="EmailID" placeholder="Email ">
                                      <small style="color:red" class="text-danger" *ngIf="Form.controls.EmailID.touched && Form.controls.EmailID.errors?.required">EmailID is required</small>
                                  </div>
                              </div>

                              <div class="col-lg-4">
                                  <div class="form-group">
                                      <label><p>Driving License Number</p></label>
                                      <input class="form-control" formControlName="DrivingLicense" type="text" [(ngModel)]="DrivingLicense" placeholder="Driving License">
                                      <small style="color:red" class="text-danger" *ngIf="Form.controls.DrivingLicense.touched && Form.controls.DrivingLicense.errors?.required">Driving License is required</small>
                                  </div>
                              </div>

                              <div class="col-lg-4">
                                  <div class="form-group">
                                      <label><p>Date Of Birth</p></label>
                                      <input matInput type="date" class="form-control" [formControl]="Form.controls.DOB" name="TentativeStartdate" placeholder="Date Of Birth">
                                      <small style="color:red" class="text-danger" *ngIf="Form.controls.DOB.touched && Form.controls.DOB.errors?.required">Date Of Birth is required</small>
                                  </div>
                              </div>

                              <div class="col-lg-4">
                                  <div class="form-group">
                                      <label><p>SSN</p></label>
                                      <input class="form-control" formControlName="SSN" type="text" [(ngModel)]="SSN" placeholder="SSN ">
                                      <small style="color:red" class="text-danger" *ngIf="Form.controls.SSN.touched && Form.controls.SSN.errors?.required">SSN is required</small>
                                  </div>
                              </div>
                          </div>


                          <div class="row">
                              <div class="col-lg-3">
                                  <div class="form-group">
                                      <label><p>Driving License IMG </p></label>
                                      <div class="images-container">
                                          <div class="m-image-wrapper">
                                              <img [src]="DLIMG" *ngIf="DLIMG" style="cursor:pointer;height: 180px;width:17rem;">
                                          </div>
                                      </div>
                                      <br />
                                      <input formControlName="DLIMG" type="file" (change)="detectFiles($event, 'DLIMG')" class="m-img-upload-btn" />
                                      <small style="color:red" class="text-danger" *ngIf="Form.controls.DLIMG.touched && Form.controls.DLIMG.errors?.required">Image is required</small>
                                  </div>
                              </div>

                              <div class="col-lg-3">
                                  <div class="form-group">
                                      <label><p>W2Form</p></label>
                                      <div class="pdf-container">
                                          <div class="m-image-wrapper">
                                              <img [src]="W2Form" *ngIf="W2Form" style="cursor:pointer;height: 180px;width:17rem;">
                                          </div>
                                      </div>
                                      <br />
                                      <input formControlName="W2Form" type="file" (change)="detectFiles($event, 'W2Form')" class="m-img-upload-btn" />
                                      <small style="color:red" class="text-danger" *ngIf="Form.controls.W2Form.touched && Form.controls.W2Form.errors?.required">Image is required</small>
                                  </div>
                              </div>

                              <!--<div class="col-lg-3">
        <div class="form-group">
            <label><p>W2Form</p></label>
            <div class="pdf-container">
                <div class="m-image-wrapper">
                    <object [data]="W2Form" *ngIf="W2Form" type="application/pdf" style="width: 100%; height: 300px;"></object>
                </div>
            </div>
            <br />
            <input formControlName="W2Form" type="file" (change)="detectFiles($event, 'W2Form')" accept="application/pdf" class="pdf-upload-btn" />
            <small style="color: red" class="text-danger" *ngIf="Form.controls.W2Form.touched && Form.controls.W2Form.errors?.required">PDF Document is required</small>

            Add a button to navigate to another page
            <button (click)="viewPdf()" [disabled]="!W2Form">View PDF</button>
        </div>
    </div>-->
                              <!--<div class="col-lg-3">
        <div class="form-group">
            <label><p>W2Form</p></label>
            <div class="pdf-container">
                <object [data]="pdfDocument" *ngIf="pdfDocument" type="application/pdf" style="cursor:pointer;height: 180px;width:17rem;"></object>
            </div>
            <br />
            <input formControlName="pdfDocument" type="file" (change)="detectFiles($event, 'pdfDocument')" accept="application/pdf" class="pdf-upload-btn" />
            <small style="color:red" class="text-danger" *ngIf="Form.controls.pdfDocument.touched && Form.controls.pdfDocument.errors?.required">PDF Document is required</small>
        </div>
    </div>-->


                              <div class="col-lg-3">
                                  <div class="form-group">
                                      <label><p>Form1099</p></label>
                                      <div class="images-container">
                                          <div class="m-image-wrapper">
                                              <img [src]="Form1099" *ngIf="Form1099" style="cursor:pointer;height: 180px;width:17rem;">
                                          </div>
                                      </div>
                                      <br />
                                      <input formControlName="Form1099" type="file" (change)="detectFiles($event, 'Form1099')" class="m-img-upload-btn" />
                                      <small style="color:red" class="text-danger" *ngIf="Form.controls.Form1099.touched && Form.controls.Form1099.errors?.required">Image is required</small>
                                  </div>
                              </div>



                              <!--<div class="col-lg-3">
                                  <div class="form-group">
                                      <label><p>AddMoreDoc</p></label>
                                      <div class="col-lg-3" formArrayName="documents">
                                          <ng-container *ngFor="let document of documentForms.controls; let i=index">
                                              <div class="document-item" [formGroupName]="i">

                                                  <div class="form-group">
                                                      <label class="fntsize"><b>Document Name</b><b><span style="color:red;">*</span></b></label>
                                                      <input class="form-control" formControlName="AddMoreDoc" type="text" placeholder="Enter Document Name" style="height: 25px;">
                                                      <small class="error-message" *ngIf="document.get('DocumentName').touched && document.get('DocumentName').errors?.required">Document Name is required</small>
                                                  </div>

                                                  <div class="file-input-container">
                                                       Input for uploading PDF or image 
                                                      <input formControlName="PDFPath" type="file" (change)="detectFiles3($event, i)" accept="image/*, application/pdf" class="file-input">
                                                      <span class="file-input-label">Choose document</span>
                                                      <small class="error-message" *ngIf="document.get('PDFPath').touched && document.get('PDFPath').errors?.required">Document file is required</small>
                                                      <small class="error-message" *ngIf="document.get('PDFPath').touched && !isImageOrPDF(document.get('PDFPath').value)">Please upload a valid image or PDF</small>
                                                  </div>

                                                  <div class="col-lg-3" style="margin-top: -3px;">
                                                      <div class="form-group">

                                                          <p class="add-button-container">
                                                              <button type="button" class="btn btn-primary m-t-15 waves-effect" (click)="AddMoreDoc()" style="border-radius: 50%; width: 3rem !important; margin-top: -10px; height: 3rem !important; margin-left: 7rem; margin-top: 5rem;"><span style="font-size: 21px !important;">+</span></button>
                                                          </p>
                                                      </div>
                                                  </div>
                                              </div>
                                          </ng-container>
                                      </div>
                                  </div>
                              </div>-->

                           


                              <!--<div class="col-lg-3">
        <div class="form-group">
            <label><p>AddMoreDoc</p></label>
            <div class="images-container">
                <div class="m-image-wrapper">
                    <img [src]="AddMoreDoc" *ngIf="AddMoreDoc" style="cursor:pointer;height: 180px;">
                </div>
            </div>
            <br />
            <input formControlName="AddMoreDoc" type="file" (change)="detectFiles($event, 'AddMoreDoc')" class="m-img-upload-btn" />
            <small style="color:red" class="text-danger" *ngIf="Form.controls.AddMoreDoc.touched && Form.controls.AddMoreDoc.errors?.required">Image is required</small>
        </div>
    </div>-->
                              <div class="col-lg-1" style="margin-top: -3px; ">
        <div class="from-group">
            <label><p>AddMoreDoc</p></label>
            <p class="add-button-container">
                <button type="button" class="btn btn-primary m-t-15 waves-effect" (click)="AddMoreDoc()" style=" border-radius: 50%; width: 3rem !important; margin-top: -10px; height: 3rem !important;margin-left:7rem;margin-top:5rem; "><span style="font-size: 21px !important;">+</span></button>
            </p>
        </div>
    </div
                              >


                              <!--<div class="form-group">
        <label class="fntsize"><b>DocPath</b><b><span style="color:red;">*</span></b></label>
        <div class="images-container">
            <div class="m-image-wrapper">
                <a [href]="document.get('imagefile').value" target="_blank">
                    <img src="../../../../assets/img/PDF1.png" *ngIf="document.get('imagefile').value" class="preview-image">
                </a>

            </div>

        </div>
    </div>-->

                              <div class="row">
                                  <div class="col-lg-3">
                                      <div class="form-group">
                                          <label><p>City </p></label>
                                          <div class="form">
                                              <p-autoComplete [suggestions]="deptList1" (completeMethod)="searchcount1($event)"
                                                              field="CityName" [dropdown]="true" [size]="90"
                                                              placeholder="Search City" [minLength]="1" [style]="{'height': '33px','font-size':'15px !important'}"
                                                              formControlName="CityName">

                                                  <ng-template let-autofillCustomers pTemplate="item">

                                                      <div>
                                                          <div class="row">
                                                              <div class="dropdown">
                                                                  <div style="height:15px;margin-left:10px;margin-bottom:5px;">
                                                                      {{autofillCustomers.CityName}}
                                                                  </div>
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </ng-template>
                                              </p-autoComplete>

                                          </div>
                                      </div>
                                  </div>

                                  <div class="col-lg-3">
                                      <div class="form-group">
                                          <label><p>State </p></label>
                                          <div class="form">
                                              <p-autoComplete [suggestions]="deptList2" (completeMethod)="searchcount4($event)"
                                                              field="StateName" [dropdown]="true" [size]="90"
                                                              placeholder="Search State" [minLength]="1" [style]="{'height': '33px','font-size':'15px !important'}"
                                                              formControlName="StateName">
                                                  <ng-template let-autofillCustomers pTemplate="item">
                                                      <div>
                                                          <div class="row">
                                                              <div class="dropdown">
                                                                  {{autofillCustomers.StateName}}
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </ng-template>

                                              </p-autoComplete>

                                          </div>
                                      </div>
                                  </div>




                                  <div class="col-lg-3">
                                      <div class="form-group">
                                          <label><p>Country </p></label>
                                          <div class="form">
                                              <p-autoComplete [suggestions]="deptList" (completeMethod)="searchcount3($event)"
                                                              field="CountryName" [dropdown]="true" [size]="90"
                                                              placeholder="Search Country" [minLength]="1" [style]="{'height': '33px','font-size':'15px !important'}"
                                                              formControlName="CountryName">
                                                  <ng-template let-autofillCustomers pTemplate="item">
                                                      <div>
                                                          <div class="row">
                                                              <div class="dropdown">
                                                                  <div style="height:15px;margin-left:10px;margin-bottom:5px;">
                                                                      {{autofillCustomers.CountryName}}
                                                                  </div>
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </ng-template>

                                              </p-autoComplete>

                                          </div>
                                      </div>
                                  </div>

                                  <div class="col-lg-3">
                                      <div class="form-group">
                                          <label><p>Fiilling Status </p></label>
                                          <div class="form">
                                              <p-autoComplete [suggestions]="deptList6" (completeMethod)="searchcount6($event)"
                                                              field="FiillingStatus" [dropdown]="true" [size]="90"
                                                              placeholder="Search FiillingStatus" [minLength]="1"
                                                              [style]="{'height': '33px','font-size':'15px !important'}"
                                                              formControlName="FiillingStatus">
                                                  <ng-template let-autofillCustomers pTemplate="item">
                                                      <div>
                                                          <div class="row">
                                                              <div class="dropdown">
                                                                  <div style="height:15px;margin-left:10px;margin-bottom:5px;">
                                                                      <a (click)="selectFillingStatus(autofillCustomers.FiillingStatus)">
                                                                          {{autofillCustomers.FiillingStatus}}
                                                                      </a>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </ng-template>
                                              </p-autoComplete>


                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                                  </div>
                              </div>



                              </div>
</form>



          <br />

          <div class="card"*ngIf="selectedFillingStatus === true" [formGroup]="Form">

              <div class="row" >

                  <div class="col-lg-4">
                      <div class="form-group">
                          <label><p>Spouse Name</p></label>
                          <input class="form-control" formControlName="SpouseName" type="text" [(ngModel)]="SpouseName" placeholder="SpouseName">
                          <small style="color:red" class="text-danger" *ngIf="Form.controls.SpouseName.touched && Form.controls.SpouseName.errors?.required">SpouseName is required</small>
                      </div>
                  </div>

                  <div class="col-lg-4">
                      <div class="form-group">
                          <label><p>Spouse MobileNumber</p></label>
                          <input class="form-control" formControlName="S_MobileNumber" type="text" [(ngModel)]="S_MobileNumber" placeholder="SpouseMobileNumber">
                          <small style="color:red" class="text-danger" *ngIf="Form.controls.S_MobileNumber.touched && Form.controls.S_MobileNumber.errors?.required">SpouseMobileNumber is required</small>
                      </div>
                  </div>

                  <div class="col-lg-4">
                      <div class="form-group">
                          <label><p>Spouse Date Of Birth</p></label>
                          <input matInput type="date" class="form-control" formControlName="S_DOB" name="TentativeStartdate" placeholder="Date Of Birth">
                          <!--    <small style="color:red" class="text-danger" *ngIf="Form.controls.SpouseDateOfBirth.touched && Form.controls.SpouseDateOfBirth.errors?.required">SpouseDate Of Birth is required</small>-->
                      </div>
                  </div>
                  <div class="col-lg-4">
                      <div class="form-group">
                          <label><p>Spouse SSN</p></label>
                          <input class="form-control" formControlName="S_SSN" type="text" [(ngModel)]="S_SSN" placeholder="SpouseSSN">
                          <small style="color:red" class="text-danger" *ngIf="Form.controls.S_SSN.touched && Form.controls.S_SSN.errors?.required">Spouse MobileNumber is required</small>
                      </div>
                  </div>
                  <div class="col-lg-4">
                      <div class="form-group">
                          <label><p>Spouse EmailID</p></label>
                          <input class="form-control" formControlName="S_EmailID" type="text" [(ngModel)]="S_EmailID" placeholder="Spouse EmailID">
                          <small style="color:red" class="text-danger" *ngIf="Form.controls.S_EmailID.touched && Form.controls.S_EmailID.errors?.required">Spouse EmailID is required</small>
                      </div>
                  </div>

                  <div class="col-lg-4">
                      <div class="form-group">
                          <label><p>Spouse Driving License</p></label>
                          <input class="form-control" formControlName="S_DrivingLicense" type="text" [(ngModel)]="S_DrivingLicense" placeholder=" spouse Driving License">
                          <small style="color:red" class="text-danger" *ngIf="Form.controls.S_DrivingLicense.touched && Form.controls.S_DrivingLicense.errors?.required">S_DrivingLicense is required</small>
                      </div>
                  </div>


                  <div class="col-lg-4">
                      <label><p>Spouse City </p></label>
                      <div class="form-group">
                          <div class="form" >
                              <p-autoComplete [suggestions]="deptList1" (completeMethod)="searchcount1($event)" 
                                              field="CityName" [dropdown]="true" [size]="90"
                                              placeholder="Search City" [minLength]="1" [style]="{'height': '33px','font-size':'15px !important'}"
                                              formControlName="S_CityName">
                                  <ng-template let-autofillCustomers pTemplate="item">
                                      <div>
                                          <div class="row">
                                              <div class="dropdown">
                                                  <div style="height:15px;margin-left:10px;margin-bottom:5px;">
                                                      {{autofillCustomers.CityName}}
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </ng-template>

                              </p-autoComplete>

                          </div>
                      </div>
                  </div>

                  <div class="col-lg-4">
                      <div class="form-group">
                          <label><p>Spouse State </p></label>
                          <div class="form">
                              <p-autoComplete [suggestions]="deptList2" (completeMethod)="searchcount4($event)"
                                              field="StateName" [dropdown]="true" [size]="90"
                                              placeholder="Search State" [minLength]="1" [style]="{'height': '33px','font-size':'15px !important'}"
                                              formControlName="S_StateName">
                                  <ng-template let-autofillCustomers pTemplate="item">
                                      <div>
                                          <div class="row">
                                              <div class="dropdown">
                                                  <div style="height:15px;margin-left:10px;margin-bottom:5px;">
                                                      {{autofillCustomers.StateName}}
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </ng-template>

                              </p-autoComplete>

                          </div>
                      </div>
                  </div>
                  <div class="col-lg-4">
                      <div class="form-group">
                          <label><p> Spouse Country </p></label>
                          <div class="form">
                              <p-autoComplete [suggestions]="deptList" (completeMethod)="searchcount3($event)"
                                              field="CountryName" [dropdown]="true" [size]="90"
                                              placeholder="Search Country" [minLength]="1" [style]="{'height': '33px','font-size':'15px !important'}"
                                              formControlName="S_CountryName">
                                  <ng-template let-autofillCustomers pTemplate="item">
                                      <div>
                                          <div class="row">
                                              <div class="dropdown">
                                                  <div style="height:15px;margin-left:10px;margin-bottom:5px;">

                                                      {{autofillCustomers.CountryName}}
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </ng-template>

                              </p-autoComplete>

                          </div>
                      </div>
                  </div>

              </div>

          </div>
          <div class="row">
              <div class="col-lg-2"></div>
              <div class="col-lg-5"></div>
              <div class="col-lg-5">
                  <br />
                  <button class="button-33" role="button" (click)="Submit(Form.value)"> Save</button>
              </div>
          </div>

      </div>


