<nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container">
        <a class="navbar-brand" routerLink="/">
            <img src="..\..\assets\img\lawyer1.png" class="logo" />
           
        </a>
        <button class="navbar-toggler" type="button">
            <span class="burger-menu">
                <span class="top-bar"></span>
                <span class="middle-bar"></span>
                <span class="bottom-bar"></span>
            </span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
         
            <ul class="navbar-nav ml-auto for-responsive">
                <li class="nav-item"><span class="nav-link" >Home</span></li>
                <li class="nav-item"><span class="nav-link" >About</span></li>
             b
                <li class="nav-item"><span class="nav-link" >Contact</span></li>
                <li class="nav-item"><span class="nav-link"><a href="login">Login</a></span></li>
            </ul>
        </div>
    </div>
</nav>
