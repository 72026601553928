import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { GeneralService } from '../Services/generalservice.service';
import { FormGroup, FormBuilder, FormsModule, Validators } from '@angular/forms'
import { MessageService } from 'primeng/api';
import { HttpClient, HttpHandler, HttpResponse, HttpRequest } from '@angular/common/http';
import Swal from 'sweetalert2';
import { Directive, HostListener } from '@angular/core'

import { Router, ActivatedRoute } from '@angular/router';
declare var $

@Component({
    selector: 'app-subscription',
    templateUrl: './subscription.component.html',
    styleUrls: ['./subscription.component.scss'],
    providers: [MessageService]

})
export class SubscriptionComponent implements OnInit {

    cartData = [];
    ProductData = [];
    CatlogID: any;
    Specdata = []
    CatID: any;
    EnquiryAry: any = [];
    formGroup: any;
    admin: any;
    TemplateId: any;
    MessageText: any;
    //ApiURL: any;
    contactForm: FormsModule;
    spinner: boolean = false;
    imgBase64: any = '';
    append: any;
    HomeUrl: any;
    Catalog_No: any;
    whatsAppProductURL: string;
    dataResult1: any;
    Pdescription: any;
    Pack_Price: any;
    GSTprice: number;
    Pack_PricewithGst: any;
    Package_Name: any;
    LoginDetails: any;
    addressDetails: any;
    addressId: any;
    arr: any = [];
    n: any;
    subTotal: any;
    totalPrice: any;
    deliveryCharge: any;
    val: any;
    PaymentType: string;
    toast: any;
    cartService: any;
    Package_ID: any;
    PaymentID: any;
    TransactionID: any;
    TransactionStatus: any;
    loginDet: any;
    formData: any;

    constructor(public generalService: GeneralService, public route: ActivatedRoute,
        public router: Router, public http: HttpClient,) {
        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.HomeUrl = data.Webservice;

            debugger
            this.loginDet = JSON.parse(localStorage.getItem("LoginDetails"));

        });

    }

    ngOnInit(): void {
        debugger
        ////$('#DeptModal').modal('show');
        // this.GetSpecificationdata(this.CatlogID)
        this.ProductData = [];
        this.GetPacages();

    }

    //openModal(item) {
    //    debugger
    //    if (this.loginDet != null) {


    //        debugger
    //        if (item.packagePrice == 0) {
    //            const currentDate = new Date();
    //            const createdDate = new Date(this.loginDet.CreatedDate);
    //            const timeDifference = currentDate.getTime() - createdDate.getTime();
    //            const daysDifference = timeDifference / (1000 * 3600 * 24); // Convert milliseconds to days

    //            if (daysDifference <= 30) {
    //                this.generalService.ShowAlert('Info', 'You have  30-day free trial', 'Info');
    //                this.router.navigate(['/Judges']);
    //            }
    //            else {
    //                this.generalService.ShowAlert('Info', 'You are 30-day free trial completed please subscribe', 'Info');

    //            }
    //        }
    //        else {
    //            $('#DeptModal').modal('show');
    //            var Gst = 15;
    //            this.Package_ID = item.PackageID
    //            this.Pdescription = item.PackageDiscription;
    //            this.Pack_Price = item.packagePrice;
    //            this.Package_Name = item.PackageName;
    //            this.Pack_PricewithGst = ((item.packagePrice * Gst) / 100) + item.packagePrice;
    //            this.GSTprice = ((item.packagePrice * Gst) / 100)
    //        }
    //    }
    //    else {
    //        this.generalService.ShowAlert('Info', 'Please Login', 'Info');
    //        this.router.navigate(['/login']);

    //    }
    //}


    openModal(item) {
        $('#myModal1').modal('show');
        debugger
        if (this.loginDet != null) {
            debugger
            if (item.packagePrice == 0) {
                const currentDate = new Date();
                const createdDate = new Date(this.loginDet.CreatedDate);
                const timeDifference = currentDate.getTime() - createdDate.getTime();
                const daysDifference = timeDifference / (1000 * 3600 * 24); // Convert milliseconds to days             

                if (daysDifference <= 30) {
                    debugger
                    this.Package_ID = item.PackageID
                    this.Pdescription = item.PackageDiscription;
                    this.Pack_Price = item.packagePrice;
                    this.Package_Name = item.PackageName;
                    this.PlaceOrder(item.PackageID);
                    //this.generalService.ShowAlert('Info', 'You have  30-day free trial', 'Info');
                    //this.router.navigate(['/profile']);
                }
                else {
                    this.generalService.ShowAlert('Info', 'You are 30-day free trial completed please subscribe', 'Info');

                }
            }
            else {
                $('#myModal1').modal('show');
                var Gst = 15;
                this.Package_ID = item.PackageID
                this.Pdescription = item.PackageDiscription;
                this.Pack_Price = item.packagePrice;
                this.Package_Name = item.PackageName;
                this.Pack_PricewithGst = ((item.packagePrice * Gst) / 100) + item.packagePrice;
                this.GSTprice = ((item.packagePrice * Gst) / 100)

              
            }
        }
        else {
            this.generalService.ShowAlert('Info', 'Please Login', 'Info');
            this.router.navigate(['/login']);

        }
    }


    procedtoBuy1(item) {
        debugger
       
           
            $('#myModal1').modal('show');
        this.PlaceOrder(item);

    }

    GetPacages() {
        debugger

        this.arr = [];
     
 

        this.arr.push({

            //UserID: this.loginDet.CustomerID,
            //TokenID: this.loginDet.TokenID,
            packagePrice:0

        });

        const formData = new FormData();
        formData.append('Param', JSON.stringify(this.arr));
        formData.append('Flag', '4');
        const url = "api/Payertax/Package_CRUD";
        this.generalService.PostData(url, formData).then(data => {
            debugger
            this.dataResult1 = data;

        },
            err => {
                this.generalService.ShowAlert('ERROR', 'Something went wrong,please try agian later', 'error');
            })
    }

    back() {
        this.router.navigateByUrl('/login');
    }

    close() {
        $('#DeptModal').modal('hide');
        $('#myModal1').modal('hide');
    }

    sendWhatsapp(item) {
        var prodName = ""
        var str = item.ChemicalName.split(" ")//spliting the category name into string array where space occured

        prodName = str;

        this.whatsAppProductURL = "https://Altrakem.com/%23/Products/" + prodName
        window.open("https://api.whatsapp.com/send?&text=" + this.whatsAppProductURL)
    }

    PlaceOrder(item) {
        debugger

        this.arr = [];
        this.n = ""
        var d = new Date();
        this.n = d.getTime();
        //this.offerPrice = !this.offerPrice ? 0 : !this.offerPrice;

        this.arr.push({

            CustomerID: this.loginDet.CustomerID,
            TokenID: this.loginDet.CurrentTokenID,
            SubscribtionNo: item,
            PaymentType: this.PaymentType,
            Status: this.loginDet.Status,
            TransactionID: this.PaymentType === 'HandCash' ? 0 : this.TransactionID,
            TransactionStatus: this.PaymentType === 'HandCash' ? 0 : this.TransactionStatus,

            PackageID: this.Package_ID,
            packagePrice: this.Pack_Price
        });

        let formData: FormData = new FormData();
         
        formData.append('Param', JSON.stringify(this.arr));
        formData.append('Flag', '1');
        var url = "api/Payertax/SP_Payment_CRUD"
        // this.http.post(url, formData).pipe(map(res => res)).subscribe((data: any) => {
        this.generalService.PostData(url, formData).then((data: any) => {
            debugger
            if (data != "") {

                this.generalService.ShowAlert("Success", "Your Subscription added successfully", "Success");
                this.router.navigate(['/Profile']).then(() => { window.location.reload(); });
                //if (this.PaymentType === "HandCash") {

                //    this.generalService.ShowAlert("Success", "Your Subscription added successfully", "Success");
                //    this.router.navigate(['/profile']);
                //    this.cancel();
                //}
                //else if (this.PaymentType = "OnlinePay") {
                //    this.cancel()
                //      this.toast.add({ severity: 'success', summary: 'Success', life: 1000, detail: 'Your Subscription added successfully' });
                //    this.generalService.ShowAlert("Success", "Your Subscription added successfully", "Success");


                //    this.procedtoBuy(this.arr, this.n)
                //    this.router.navigate['/profile'];
                //}



            }


        });

    }

    Login(mobile, password) {
        debugger
        this.arr = []
        this.arr.push({
            //UserID: this.loginDet.UserID,
            //TokenID: this.loginDet.TokenID,
            Mobile: mobile,
            Password: password,

        })
        var UploadFile = new FormData();
        UploadFile.append("Param", JSON.stringify(this.arr));
        var url = " api/Payertax/Login";
        this.generalService.PostData(url, UploadFile).then(data => {
            debugger
            if (data != null) {
                debugger
                if (data != "Invalid User") {

                    this.loginDet = JSON.stringify(data[0]);
                    localStorage.setItem("LoginDetails", this.loginDet);
                    //this.generalService.ShowAlert('WARNING', 'Please Enter Valid Credentials.', 'warning');
                    if (data[0].PackageID != 0) {
                        this.router.navigate(['/profile']).then(() => {
                            window.location.reload(); // Perform a complete page reload
                        });
                    }
                    else {
                        this.router.navigate(['/subscription']).then(() => {
                            window.location.reload(); // Perform a complete page reload

                        });

                    }

                }
                else {
                    this.generalService.ShowAlert('WARNING', 'Please Enter Valid Credentials.', 'warning');
                }

            }
            else {
                this.generalService.ShowAlert("WARNING", "Please Enter Valid Credintials", "warning");
            }
        }, error => ({
            title: 'ERROR',
            text: 'Something went wrong. Please try again later.',
            type: 'error',
            confirmButtonText: 'Ok'

        })
        );

    }

    private updatePackageID(packageID: string): void {
        debugger
        const url = "api/Payertax/Registration_Crud";
        const formData: FormData = new FormData();
        formData.append('UserID', this.loginDet.CustomerID);
        formData.append('PackageID', packageID);
        this.generalService.PostData(url, formData).then((data: any) => {
            // Handle the response if needed
        }).catch(error => {
            console.error('Error updating PackageID:', error);
        });
    }


    cancel() {
        $('#DeptModal').modal('hide');
        $('#myModal1').modal('hide');
    }
    selectMood() {

        $('#myModal1').modal('show');

    }

    procedtoBuy(CartProducts, n) {
        debugger;
        var UploadFile = new FormData();
        this.subTotal = this.Pack_PricewithGst;
        UploadFile.append('Name', this.loginDet.CustomerName);
        UploadFile.append('Mobile', this.loginDet.Mobile);
        UploadFile.append('Email', this.loginDet.EmailID);
        UploadFile.append('Amount', this.subTotal);
        UploadFile.append('TransactionId', n);
        UploadFile.append('BookingDetails', JSON.stringify(CartProducts));

        console.log("localStorage LoginDetails:", localStorage.getItem("LoginDetails"));
        console.log("loginDet before assignment:", this.loginDet);

        // ...
        var url = "api/Payertax/PaymentGatewayWeb1";
        this.generalService.PostData(url, UploadFile).then((data: any) => {
            debugger;
            window.location.href = data.toString();
            this.cancel()
            // alert ('hello')
            // this.toast.add({ severity: 'success', summary: 'Success', life: 1000, detail: 'Your order placed successfully' });

            this.cartService.removeAllcart()
            this.val = 1

            this.router.navigate['/profile'];
            debugger

        }, err => {
            // this.router.navigate['/Cart'];
        });

    }

 

    getPaymentType(pType) {
        this.PaymentType = pType
    }




}
