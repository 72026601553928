<!DOCTYPE html>
<html>
<head>
    <title>PDF Viewer</title>
    <style>
        #pdf-container {
            width: 100%;
            height: 600px;
        }
    </style>
</head>
<body>
    <div id="pdf-container">
        <iframe src="{{ PDFPath1 }}" width="100%" height="100%"></iframe>
        <p>Your web browser doesn't support embedding PDF files. You can <a href="{{ PDFPath }}">click here to download the PDF file</a> instead.</p>
    </div>
</body>
</html>
