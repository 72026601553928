<div class="top-bar">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-7">
                <div class="logo">
                
                        <p class="mail"><i class="fa fa-envelope"></i>&nbsp;info@Payertax.com</p>

                        <!--<h1>Lawyers&nbsp;Choice</h1>-->
                        <!-- <img src="img/logo.jpg" alt="Logo"> -->
             
                </div>
            </div>
            <div class="col-lg-5">
                <div class="top-bar-right">
                    <!--<div class="text">
                        <h2>8:00 - 9:00</h2>
                        <p>Opening Hour Mon - Fri</p>
                    </div>
                    <div class="text">
                        <h2>+123 456 7890</h2>
                        <p>Call Us For Free Consultation</p>
                    </div>-->
                    <div class="social">
                        <a href=""><i class="fab fa-twitter"></i></a>
                        <a href=""><i class="fab fa-facebook-f"></i></a>
                        <a href=""><i class="fab fa-linkedin-in"></i></a>
                        <a href=""><i class="fab fa-instagram"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Top Bar End -->
<!-- Nav Bar Start -->
<div class="nav-bar">
    <div class="container-fluid">
        <nav class="navbar navbar-expand-lg bg-dark navbar-dark">
            <a href="#" class="navbar-brand">
              
                <img src="../../../../assets/PayerTax/logo.png" style="width:50px;border-radius:50px;"/>
            </a>
            <button type="button" class="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse">
                <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse justify-content-between" id="navbarCollapse">
                <div class="logoo">
                    <img src="../../../../assets/PayerTax/logo.png" class="logoo"/>
                </div>
                <div class="navbar-nav ">
                    <a href="" class="nav-item nav-link active">Home</a>
                    <a routerLink="/about-us"class="nav-item nav-link">About Us</a>
                    <a routerLink="/Profile" class="nav-item nav-link">Profile</a>
                    <a routerLink="/Packages" class="nav-item nav-link">Packages</a>

                    <!--<div class="nav-item dropdown">
        <a href="#" class="nav-link dropdown-toggle" data-toggle="dropdown">About Us</a>
        <div class="dropdown-menu">
            <a href="about.html" class="dropdown-item">About1</a>
            <a href="" class="dropdown-item">About2</a>
        </div>
    </div>-->
                    <!--<a routerLink="/Judges" class="nav-item nav-link" *ngIf="LoginDetails!=null">Judgements</a>-->

                    <a routerLink="/contact" class="nav-item nav-link">Contact</a>
                    <!--<a routerLink="/Registration" class="nav-item nav-link">Registration</a>-->
                    <a routerLink="/login" class="nav-item nav-link" *ngIf="LoginDetails==null">Login</a>
                    <a (click)="Logout()" class="nav-item nav-link" *ngIf="LoginDetails!=null">Logout</a>

                </div>
                
            </div>
        </nav>
    </div>
</div>
