<div class="container-fluid banner_image breadcrumb__overlay">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="breadcrumb_heading">
                    <div class="banner_heading">Packages</div>
                    <nav aria-label="breadcrumb crumb">
                        <ol class="breadcrumb">
                            <li class="Home"><a routerLink="/">Home >></a></li>
                            <li style="color:white;"><a>Packages</a></li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</div><br />



      <div class="container">
          <section class="choose-package">
              <div class="container">
                  <div class="row">
                      <div class="col-lg-8 offset-lg-2" id="price">
                          <h5 class="special-heading text-center">Choose</h5>
                          <h1 class="text-center">Package</h1>
                      </div>
                  </div>
                  <div class="divider-45 d-none d-lg-block"></div>
                  <div class="row c-gutter-60">
                      <div class="col-lg-4 col-12" *ngFor="let item of dataResult1">
                          <div class="pricing-plan hero-bg rounded">

                              <div class="plan-name text-uppercase bg-maincolor2">
                                  <h3>{{item.PackageName}}</h3>
                              </div>
                              <div class="plan-desc">
                                  <div class="price-icon">
                                      <img src="../../assets/PayerTax/images (1).jpg" alt="" style=" width: 41%;" />
                               
                                  </div>
                              </div>
                              <div class="price-wrap d-flex">
                                  <span class="plan-sign small-text">Rs</span>
                                  <span class="plan-price color-main2">{{item.packagePrice}}</span>
                                  <span class="plan-decimals small-text">/$</span>
                              </div>
                              <div class="plan-button">
                                  <a class="btn btn-maincolor2"><span (click)="openModal(item)">Purchase</span></a>
                              </div>
                          </div>
                      </div>

                  </div>
              </div>
              <!--<div class="col-lg-4 col-12">
        <div class="pricing-plan hero-bg rounded">
            <div class="plan-name text-uppercase bg-maincolor2">
                <h3>
                    Gold
                </h3>
            </div>
            <div class="plan-desc">

                <div class="price-icon">
                    <img src="../../assets/PayerTax/images (1).jpg" alt="" style=" width: 41%;" />
                </div>
            </div>

            <div class="price-wrap d-flex">
                <span class="plan-sign small-text">Rs</span>
                <span class="plan-price color-main3">79</span>
                <span class="plan-decimals small-text">/$</span>
            </div>
            <div class="plan-button">
                <a href="#" class="btn btn-maincolor2"><span>Purchase</span></a>
            </div>
        </div>
    </div>
    <div class="divider-20 d-block d-md-none"></div>
    <div class="col-lg-4 col-12">
        <div class="pricing-plan hero-bg rounded">
            <div class="plan-name text-uppercase bg-maincolor3">
                <h3>
                    Siliver
                </h3>
            </div>
            <div class="plan-desc">


                <div class="price-icon">
                    <img src="../../assets/PayerTax/images.jpg" alt="" style=" width: 41%;" />
                </div>
            </div>

            <div class="price-wrap d-flex">
                <span class="plan-sign small-text">Rs</span>
                <span class="plan-price color-main5">49</span>
                <span class="plan-decimals small-text">/$</span>
            </div>
            <div class="plan-button">
                <a href="#" class="btn btn-maincolor3"><span>Purchase</span></a>
            </div>
        </div>

    </div>-->

          </section>

          <div class="row">
              <div class="col-lg-12">
                  <h5>Income Tax Return Filing:</h5>
                  <div>
                      At Payer Tax, we offer comprehensive income tax return filing services for U.S. citizens. Our streamlined process ensures that your tax return is prepared accurately and submitted on time. We handle all types of tax returns, including individual, joint, and business returns.
                  </div>
                  <h5> Status Updates:</h5>
                  <div>
                      We understand the importance of transparency and keeping you informed throughout the tax filing process. With our user-friendly online portal, you can easily track the progress of your tax return.

                  </div>
                  <h5> Our status updates include:</h5>
                  <b> Return Sent:</b>
                  <div>Once your tax return is prepared, we promptly submit it to the appropriate tax authority.</div>
                  <b>  Return Accepted by the User: </b>
                  <div>You will receive confirmation when the tax authority acknowledges the receipt of your tax return.</div>
                  <b> E-signed:</b>
                  <div>
                      Enjoy the convenience of electronically signing your tax return using our secure e-signature system, eliminating the need for physical paperwork.
                  </div> 
                  <b>  Approved: </b>
                  <div>
                      Once the tax authority reviews and approves your tax return, we will notify you. This indicates that your return has been accepted as accurate and compliant with tax laws.
                  </div>
                  <b>  Expert Guidance:</b>
                  <div>
                      Our team of tax professionals is here to assist you throughout the tax filing process. We provide expert guidance, ensuring that you take advantage of all eligible deductions, credits, and exemptions. We answer your questions, address your concerns, and strive to minimize your tax liability while maximizing your potential refund.
                  </div>
                  <div>
                      At Payer Tax, we are committed to providing reliable, accurate, and personalized tax services to U.S. citizens. With our expertise and commitment to exceptional customer service, we aim to make your tax filing experience efficient and stress-free.
                  </div>
              </div>
          </div>
      </div>
      <div class="modal" id="myModal1">
          <div class="modal-dialog" role="document">
              <div class="modal-content">
                  <div class="modal-header state modal-primary">
                      <h4 style="text-align:left;" class="modal-title fontt" id="modal-primary-label">Select Payment mode</h4>



                  </div>
                  <div class="modal-body divvv">
                      &nbsp;&nbsp; <input type="radio" name="mode" (change)="getPaymentType('HandCash')" /><span>&nbsp;&nbsp;&nbsp;Cash on delivery</span><br /><br />
                      &nbsp;&nbsp;&nbsp;<input type="radio" name="mode" (change)="getPaymentType('OnlinePay')" /><span>&nbsp;&nbsp;&nbsp;Online payment</span>
                      <br />
                      <br />
                      <div class="modal-footer">

                          <button id="closemodal" type="button" class="btn1 btn-success btnnn btnnn1" (click)="PlaceOrder(Package_ID)" style="">Ok</button>
                          <button id="closemodal" type="button" class="btn1 btn-danger btnn" (click)="cancel()" style="">Cancel</button>
                      </div>

                  </div>
              </div>
          </div>
      </div>