<div class="container-fluid banner_image breadcrumb__overlay">
    <div class="container" >
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="breadcrumb_heading">
                    <div class="banner_heading">Contact Us</div>
                    <nav aria-label="breadcrumb crumb">
                        <ol class="breadcrumb">
                            <li class="Home"><a routerLink="/">Home >></a></li>
                            <li style="color:white;"><a>Contact Us</a></li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid">
    <div class="container"style="background-color:#2269d9">
        <section class="contact-container">
            <form class="contact-form">
                <div class="heading">
                    <h2>CONTACT US</h2>
                    <p>Get In Touch</p>
                </div>
                <div class="input">
                    <i class="fa-regular fa-user"></i>
                    <input name="username" type="text" placeholder="Username" />
                </div>
                <div class="input">
                    <i class="fa-regular fa-envelope"></i>
                    <input name="email" type="email" placeholder="Email" />
                </div>
                <div class="input">
                    <i class="fa-regular fa-message"></i>
                    <textarea name="message"
                              cols="30"
                              rows="10"
                              placeholder="Message"
                              style="resize: none"></textarea>
                </div>
                <button class="button-71" role="button"> Submit</button>
            </form>
            <div class="contact-info">
                <h3 class="heading"style="color:white">OUR INFORMATION</h3>
                <ul class="contacts">
                    <li>
                        <i class="fas fa-map-marker-alt"></i>
                     123 Main Street, 
                        <div style="padding-left: 13%;">Suite 456,</div> 
                        <div  style="padding-left: 13%;">Anytown</div>
                    </li>
                    <li>
                        <i class="fa-solid fa-envelope"></i>
                        info@apexenterprises.com
                    </li>
                    <li>
                        <i class="fa-solid fa-phone"></i>
                        555-123-4567
                    </li>
                </ul>
                <div class="social-links"></div>
            </div>
        </section>
    </div>
</div>