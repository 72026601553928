import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { EmailValidators } from 'ngx-validators';
import { GeneralService } from '../../Services/generalservice.service';
import { CookieService } from 'ngx-cookie-service';
//import { EncryptionService } from '../../Services/encryption.service';
declare var $

import Swal from 'sweetalert2';

@Component({
    selector: 'app-product-details',
    templateUrl: './product-details.component.html',
    styleUrls: ['./product-details.component.scss'],

})
export class ProductDetailsComponent implements OnInit {



    constructor() { }

    ngOnInit(): void {
    }


}

