<footer class="footer-area">
    <div class="container">
        <h3><a routerLink="/"><span>Payertax</span></a></h3>
        <ul>
            <li><a href="#" target="_blank" class="fab fa-facebook-f"></a></li>
            <li><a href="#" target="_blank" class="fab fa-twitter"></a></li>
            <li><a href="#" target="_blank" class="fab fa-linkedin-in"></a></li>
            <li><a href="#" target="_blank" class="fab fa-instagram"></a></li>
            <li><a href="#" target="_blank" class="fab fa-skype"></a></li>
        </ul>
        <p>Copyright @2022 All Rights Reserved.</p>
        <a href="http://localhost:4200/"><p>Admin Login</p></a>
    </div>
</footer>
