
<app-about></app-about>

<!--<app-welcome></app-welcome>


<app-strategy></app-strategy>

<app-who-we-are></app-who-we-are>-->

<!--<app-cta></app-cta>

<app-skill></app-skill>
        
<app-funfacts></app-funfacts>-->

<!--<app-how-we-work></app-how-we-work>

<app-services-two></app-services-two>

<app-team></app-team>-->

<!--<app-why-we-different></app-why-we-different>

<app-work></app-work>

<app-pricing></app-pricing>

<app-faq></app-faq>

<app-feedback></app-feedback>

<app-blog></app-blog>

<app-partner></app-partner>

<app-subscribe></app-subscribe>

<app-contact></app-contact>-->
