import { Component, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';
@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent implements OnInit {

    constructor(private viewportScroller: ViewportScroller) { }

    public onClick(elementId: string): void {
        debugger
        this.viewportScroller.scrollToAnchor(elementId);
    }

    ngOnInit() {
    }

    classApplied = false;
    toggleClass() {
        this.classApplied = !this.classApplied;
    }

}
