<div class="container-fluid bg_breadcrmb">
    <div class="container">
        <div class="breadcrumb crumb" style="cursor:pointer">
            <nav aria-label="breadcrumb">
                <div class="heading_breadcrumb">Judgements</div>
                <ul class="breadcrumb">
                    <li><a routerLink="/">Home</a></li>
                    <li><a>Judgements</a></li>
                </ul>
            </nav>
        </div>
    </div>
</div>



<div class="margn">
    <div class="container">
        <div class="row" style="margin-left: -22px;">
            <div class="col-lg-3">
                <div class="card_1 ">
                    <h2 class="heading"><span></span></h2>
                    <img src="../../../assets/img/lawimg.jpg" style="" class="img-fld bg1 incard" />
                    <h2 class="heading"><span></span></h2>
                    <!--<p class="site_text"></p>-->
                    <div class="cardn " style="margin-top: 4px;">
                        <img src="../../../assets/img/law.png" style="margin-right: 201px; margin-bottom: -20px;width: 20px;" />
                        <!--<a routerLink="" class="learn-more-butn">-->
                        <p class="card-space hover-effect" (click)="GetJudgements(1)">Supreme Court   <span class="spn"> {{TotalSCJudgements}}</span>  </p>

                    </div>
                    <div class="cardn" style="margin-top: 6px;">
                        <img src="../../../assets/img/law.png" style="margin-right: 201px; margin-bottom: -20px;width: 20px;" />
                        <!--<a routerLink="" class="learn-more-butn">-->
                        <p class="card-space" (click)="GetJudgements(3)">TS High Court  <span class="spnn"> {{TodaysTSJudgements}}</span></p>
                        <!-- </a>-->
                    </div>
                    <div class="cardn" style="margin-top: 6px;">
                        <img src="../../../assets/img/law.png" style="margin-right: 201px; margin-bottom: -20px;width: 20px;" />
                        <!--   <a routerLink="" class="learn-more-butn">-->
                        <p class="card-space" (click)="GetJudgements(2)">AP High Court  <span class="spnn"> {{TotalAPJudgements}}</span></p>
                        <!--</a>-->
                    </div>
                </div>
            </div>

            <div class="col-lg-3 ">
                <div class="card_1">
                    <div class="row">
                        <div class=" col-lg-6">
                            <input type="search" style="width: 180%;" placeholder="Search Your Doument..!" />
                        </div>
                        <div class="col-lg-6">
                            <i class="fa fa-search search" pTooltip=" Click search"></i>
                        </div>
                    </div>
                    <!--<input type="text" id="myInput" onkeyup="myFunction()" style="margin-left: 9px;height: 28px;" placeholder="Search for names.." title="Type in a name">-->
                    <div *ngFor="let person of dataResult">
                        <ul class="card middle-pane" id="myUL" (click)="Getpdf1(person.JudgementID)">
                            <li>{{person.JudgementTitle}}</li>
                            <li>{{person.Bench}}</li>
                            <li>{{person.JudgementDate}}</li>
                            <li>{{person.PDFPath}}</li>
                            <li>{{person.DOC_Name}}</li>
                            <hr />
                        </ul>
                    </div>
                </div>
            </div>

            <div id="pdf" class="col-lg-6">
                <div class="card_1" style="">
                    <div class="row">
                        <!-- Search area code... -->

                        <div class="col-lg-12" *ngIf="manohar">
                            <div class="card" style="height:100%">
                                <iframe [src]="PDFPath" type="application/pdf" width="100%" height="500px">

                                    PDF cannot be displayed
                                </iframe>
                            </div>
                        </div>

                        <div class="col-lg-10"></div>
                        <div class="col-lg-2">
                            <button type="button" class="bttn btn-success">Print</button>
                        </div>

                        <div>
                            <h2 class="heading"><span>&nbsp;</span></h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

<script>
    function myFunction() {
        var input, filter, ul, li, a, i, txtValue;
        input = document.getElementById("myInput");
        filter = input.value.toUpperCase();
        ul = document.getElementById("myUL");
        li = ul.getElementsByTagName("li");
        for (i = 0; i < li.length; i++) {
            a = li[i].getElementsByTagName("a")[0];
            txtValue = a.textContent || a.innerText;
            if (txtValue.toUpperCase().indexOf(filter) > -1) {
                li[i].style.display = "";
            } else {
                li[i].style.display = "none";
            }
        }
    }
</script>
