import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { EmailValidators } from 'ngx-validators';
import { GeneralService } from '../Services/generalservice.service';
import { CookieService } from 'ngx-cookie-service';
//import { EncryptionService } from '../Services/';
declare var $

import Swal from 'sweetalert2';




@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    arr: any[];
    logindata: string;
    loginDet: any;
    mobile: any;
    password: any;

    passwordshow: boolean = false;

    togglePasswordVisibility() {
        this.passwordshow = !this.passwordshow;
    }

    constructor(private router: Router, fb: FormBuilder,
        public generalService: GeneralService, private cookieService: CookieService,
    ) {
        //   localStorage.removeItem("LoginDetails");
        debugger
        this.loginDet = JSON.parse(localStorage.getItem("LoginDetails"));

    }
    close() {
        debugger
        $('#myModal2').modal('hide');
    }
    hide() {
        $('#myModal4').modal('hide');
    }


    ngOnInit(): void {

    }

    Login(mobile, password) {
        debugger
        this.arr = []
        this.arr.push({
            //UserID: this.loginDet.UserID,
            //TokenID: this.loginDet.TokenID,
            MobileNumber: mobile,
            Password: password,

        })
        var UploadFile = new FormData();
        UploadFile.append("Param", JSON.stringify(this.arr));
        var url = "api/Payertax/Login";
        this.generalService.PostData(url, UploadFile).then(data => {
            debugger
            if (data != null) {
                if (data != "Invalid User") {

                    this.logindata = JSON.stringify(data[0]);
                    localStorage.setItem("LoginDetails", this.logindata);
                    //this.generalService.ShowAlert('WARNING', 'Please Enter Valid Credentials.', 'warning');
                    this.router.navigate(['/Packages']).then(() => {
                        window.location.reload(); // Perform a complete page reload

                    });



                }
                else {
                    this.generalService.ShowAlert('WARNING', 'Please click ok to login here.', 'warning');

                    var UploadFile = new FormData();
                    UploadFile.append("Id", this.loginDet.CustomerID);
                    UploadFile.append("status", '0');

                    var url = "api/Payertax/UpdateLoginStatus";
                    debugger;
                    this.generalService.PostData(url, UploadFile).then(data1 => {
                        // Handle the response or perform any additional actions if needed

                    });

                }

            }
            else {
                this.generalService.ShowAlert("WARNING", "Please Enter Valid Credintials", "warning");
            }
        }, error => ({
            title: 'ERROR',
            text: 'Something went wrong. Please try again later.',
            type: 'error',
            confirmButtonText: 'Ok'

        })
        );

    }

    updatestatus(mobile) {



        
        var UploadFile = new FormData();
        debugger
        UploadFile.append("Id", this.loginDet.CustomerID);
        UploadFile.append("status", '0');

        var url = "api/Payertax/UpdateLoginStatus";
        debugger
        this.generalService.PostData(url, UploadFile).then(data1 => {

            this.router.navigate(['/subscription']);
        })
    }


}
