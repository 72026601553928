<div class="container-fluid banner_image breadcrumb__overlay">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="breadcrumb_heading">
          <div class="banner_heading">Vision & Mission With Core Values</div>
          <nav aria-label="breadcrumb crumb">
            <ol class="breadcrumb">
              <li class="Home"><a routerLink="/">Home >></a></li>
              <li style="color:white;margin-left:-15px;"><a>Vision & Mission With Core Values</a></li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container">
  <div class="row">
    <img src="../../../assets/img/new imgs/banners/map.png" style="width: 100%;aspect-ratio: auto 1095 / 365;height: 100%;" />
    <!--<img src="../../../assets/img/new imgs/banners/world_map.png" style="width: 100%;aspect-ratio: auto 1095 / 365;height: 100%;" />-->
    <div>
      <h4 style="color: #053e8d;">Vision</h4>
      <p>To be acknowledged by all of our stakeholders as a well-known manufacturer of Speciality Chemicals, Pyridine Derivative, Intermediates, and APIs.</p>
      <br />
      <h4 style="color: #053e8d;">Mission</h4>
      <br />
      <ul>
        <li style="color:black"><i class="ins-icon-list-icon margin-10px-right display-inline-block position-absolute left-0 fa fa-angle-right" style="color: #053e8d;"></i> Ensure the continued manufacture of pharmaceuticals of superior quality.</li>

        <li style="color:black"><i class="ins-icon-list-icon margin-10px-right display-inline-block position-absolute left-0 fa fa-angle-right" style="color: #053e8d;"></i> Revolutionary production and investigative solutions, establishing and nurturing relationships with clientele. </li>

        <li style="color:black"><i class="ins-icon-list-icon margin-10px-right display-inline-block position-absolute left-0 fa fa-angle-right" style="color: #053e8d;"></i> Allocating resources towards developing new products and technologies can generate significant worth.</li>

        <br />
      </ul>
    </div>

    <h4 style="color: #053e8d;">Core Values</h4>

    <div>
      <br />
      <ul>
        <li style="color:black"><i class="ins-icon-list-icon margin-10px-right display-inline-block position-absolute left-0 fa fa-angle-right" style="color: #053e8d;"></i> Quality</li>
        <li style="color:black"><i class="ins-icon-list-icon margin-10px-right display-inline-block position-absolute left-0 fa fa-angle-right" style="color: #053e8d;"></i> Reliability</li>
        <li style="color:black"><i class="ins-icon-list-icon margin-10px-right display-inline-block position-absolute left-0 fa fa-angle-right" style="color: #053e8d;"></i> Integrity</li>
        <li style="color:black"><i class="ins-icon-list-icon margin-10px-right display-inline-block position-absolute left-0 fa fa-angle-right" style="color: #053e8d;"></i>Teamwork</li>
        <li style="color:black"><i class="ins-icon-list-icon margin-10px-right display-inline-block position-absolute left-0 fa fa-angle-right" style="color: #053e8d;"></i> Innovation</li>
      </ul><br />
    </div>
  </div>
</div>



<!--<div class="container-fluid content_image">
  <div class="container">
    <div class="row">
      <div class="col-lg-7 col-md-7 col-sm-12 col-xs-12" style="margin-top:2rem;">
        <div class="row">
          <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 arrow_icon">
            <img src="../../../assets/img/new imgs/new_alt_logo1.png" style="width:100%;" />
          </div>
          <div class="col-lg-11 col-md-11 col-sm-12 col-xs-12 abt">
            <p>
              <strong>Altrakem </strong> is a Hyderabad, India based company with a primary focus on offering chemistry services to <b>pharmaceutical companies, biotech entities, academics, bio-materials, and catalysts industry</b>. Altrakem offers custom synthesis of new chemical entities, <b>API intermediates, Pyridine Derivatives </b>,Speciality chemicals reference, standards and specific Impurities from a milligram to kilogram level. In addition, Altrakem provides process research and development for large scale manufacturing with IP & confidentiality.
              
            </p>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 arrow_icon">
            <img src="../../../assets/img/new imgs/new_alt_logo1.png" style="width:100%;" />
          </div>
          <div class="col-lg-11 col-md-11 col-sm-12 col-xs-12">
            <p>
              Altrakem focus on offering chemistry services to pharmaceutical, biotech, top academia, speciality chemical industries all across the globe. The company  Research facility situated at IDA-Nacharam Industry Area, Hyderabad, Telangana.
            </p>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 arrow_icon">
            <img src="../../../assets/img/new imgs/new_alt_logo1.png" style="width:100%;" />
          </div>
          <div class="col-lg-11 col-md-11 col-sm-12 col-xs-12">
            <p>
              We provide Fast, Reliable, Cost effective delivery & multifaceted synthesis includes Custom Synthesis, Fine chemicals, pyridine derivatives, Process Development of Intermediates & Pharma Impurities from mg to kg scale and delivered within the stipulated timelines as per the client requirement.
            </p>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 arrow_icon">
            <img src="../../../assets/img/new imgs/new_alt_logo1.png" style="width:100%;" />
          </div>
          <div class="col-lg-11 col-md-11 col-sm-12 col-xs-12">
            <p>
              We have well experienced Scientific & Management Team over the experience of 12 years in the pharmaceutical field.
            </p>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 arrow_icon">
            <img src="../../../assets/img/new imgs/new_alt_logo1.png" style="width:100%;" />
          </div>
          <div class="col-lg-11 col-md-11 col-sm-12 col-xs-12">
            <p>
              Our principal role is to identify and understand the complete requirement of the client to support them better.
            </p>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 arrow_icon">
            <img src="../../../assets/img/new imgs/new_alt_logo1.png" style="width:100%;" />
          </div>
          <div class="col-lg-11 col-md-11 col-sm-12 col-xs-12">
            <p>
              We satisfy the client expectations by supplying the high purity & quality products.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 arrow_icon">
            <img src="../../../assets/img/new imgs/new_alt_logo1.png" style="width:100%;" />
          </div>
          <div class="col-lg-11 col-md-11 col-sm-12 col-xs-12">
            <p>
              Transparent communication regarding projects to our Clients at regular intervals.
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-5 col-md-5 col-sm-12 col-xs-12">
        <img src="../../../assets/img/new imgs/solution-tube.jpg" class="img_1" />
        <br /><br /><br /><br />
        <img src="../../../assets/img/new imgs/LIfe-Sciences-3-1.jpg" class="img_1" />
      </div>
    </div>            <br />

  </div>
</div>-->




